import axios from "axios";
import { NavLink } from "react-router-dom";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult, Statistic } from "../api/reponseResult";

class BoxappDashboardBackend extends BoxappBackend {
  monthlyRevenue = async (): Promise<Statistic> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/dashboard/monthlyRevenue`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Statistic;
    } catch (err) {
      throw err;
    }
  };
  latestOrdersStatus = async (): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/dashboard/latestOrdersStatus`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  latestStorageStatus = async (): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/dashboard/latestStorageStatus`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  latestMonthOrders = async (): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/dashboard/latestMonthOrders`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
}
let _BoxappDashboardBackend: BoxappDashboardBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappDashboardBackend = () => {
  if (!_BoxappDashboardBackend) {
    _BoxappDashboardBackend = new BoxappDashboardBackend();
  }
  return _BoxappDashboardBackend;
};

export { getBoxappDashboardBackend };
