import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Feature } from "../models/feature";

class BoxappFeatureBackend extends BoxappBackend {
  createFeature = async (feature: Feature): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/features`,
        feature,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateFeature = async (id: string, feature: Feature): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/features/${id}`,
        feature,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getFeatures = async (query: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/features`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: query,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappFeatureBackend: BoxappFeatureBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappFeatureBackend = () => {
  if (!_BoxappFeatureBackend) {
    _BoxappFeatureBackend = new BoxappFeatureBackend();
  }
  return _BoxappFeatureBackend;
};

export { getBoxappFeatureBackend };
