import React, { lazy, useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { BxButton } from "../../Button";
import moment from "moment-timezone";
import { withTranslation, useTranslation } from "react-i18next";

// Redux

import "./storageRent.scss";

import { useStorageContext } from "../../../context/StorageProviderContext";

import { useBranchContext } from "../../../context/BranchProviderContext";

import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { getBoxappContractBackend } from "../../../helpers/boxapp_contract_helper";

import { Order } from "src/models/order";
import { Storage } from "src/models/storage";
import { Contract } from "src/models/contract";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { Customer } from "src/models/customer";
import { getBoxappCustomerBackend } from "src/helpers/boxapp_customer_helper";

import { MultiLangText } from "src/models/multiLangText";
import { RentMode, RentPanelSteps } from "./StorageRent";
import StorageRentContractPanel from "./StorageRentContractPanel";
import StorageRentOrderPanel from "./StorageRentOrderPanel";
import { PaidItem } from "src/models/paidItem";
import { calPrice } from "src/pages/Orders/components/invoicePaidItem";
import {
  CreateDepositOrderRequest,
  CreateOrderRequest,
} from "src/helpers/boxapp_order_helper";
import contract from "src/template/contract";

export interface StorageRentNewOrderProps {
  mode: string;
  onSuccess: (action: string, storage: Storage) => void;
  onClose: () => void;
  modal: boolean;
  order: Order;
  customer: Customer;
  contract: Contract;
  interval?: number;
}

const StorageRentNewOrder = (props: StorageRentNewOrderProps) => {
  const [startDate, setStartDate] = useState<number>();
  const { i18n } = useTranslation();
  const [process, setProcess] = useState<number[]>([]);
  const { contextStorage } = useStorageContext();
  const [contract, setContract] = useState<Contract>();
  const [customer, setCustomer] = useState<Customer>(props.customer);
  const { contextBranch } = useBranchContext();
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  const [requestBody, setRequestBody] = useState<Map<string, any>>(new Map());
  const steps = [RentPanelSteps.Contract, RentPanelSteps.Order];
  const { showModal } = useGlobalModalContext();
  const [paidItems, setPaidItems] = useState<PaidItem[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  useEffect(() => {
    if (!customer && props.customer?.id)
      getBoxappCustomerBackend()
        .getCustomer(props.customer.id)
        .then((res) => {
          setCustomer(res.customer!);
        });
  }, [props.customer.id]);

  const isFinished = useCallback(
    (i: number) => process?.indexOf(i) > -1,
    [process]
  );
  const formValid = useMemo(() => {
    if (steps.length === 1) {
      return true;
    }
    return process.length >= steps.length;
  }, [process]);

  const togglemodal = () => {
    props.onClose();
  };
  const submit = async () => {
    setSubmitButtonBlock(true);
    const interval = 2000;
    const timeoutId = setTimeout(() => {
      setSubmitButtonBlock(false);
      clearTimeout(timeoutId);
    }, interval);
    try {
      const contractId = props.contract?.id ?? contract?.id;
      // let _contract = contract;
      if (contextBranch?.id && contextStorage?.id && contractId) {
        const res = await getBoxappStorageBackend().rentStorage(
          contextBranch?.id,
          contextStorage?.id,
          {
            ...requestBody.get(RentPanelSteps.Order),
            contract_id: contractId,
          }
        );

        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: i18n.t("rent rental location Success"),
          messageType: "success",
          message: i18n.t("rent rental location Success"),
        });
        if (props.onSuccess && res.storage)
          props.onSuccess(props.mode, res.storage);
      }
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("rent rental location failed"),
        messageType: "error",
        message: errorMsg,
      });
    }
  };

  useEffect(() => {
    if (props.contract?.master_contract_id) {
      fetchLastOrder();
    }
  }, [props.contract]);

  const fetchLastOrder = async () => {
    if (props.contract?.master_contract_id) {
      const res = await getBoxappContractBackend().getLastOrder(
        props.contract?.master_contract_id,
        {}
      );
      if (res?.order) {
        const lastOrder = res?.order;
        const orderItems: PaidItem[] = lastOrder?.invoices
          ?.flatMap((invoice) => {
            if (
              invoice.invoice_type === "Payment" &&
              invoice?.status !== "CANCELLED"
            ) {
              return invoice.paid_items?.filter(
                (item) => item?.item_type !== "PREPAID"
              );
            }
            return [];
          })
          ?.sort((a, b) => Number(a?.sort) - Number(b?.sort)) as PaidItem[];

        if (orderItems && orderItems?.length > 0) {
          const storage = orderItems.find(
            (item) => item?.item_type === "STORAGE"
          );
          const items: PaidItem[] = [
            storage as PaidItem,
            ...orderItems.filter((item) => item?.item_type !== "STORAGE"),
          ];

          setPaidItems(
            items?.map((item, i) => {
              console.log(i);
              switch (item?.item_type) {
                case "STORAGE":
                  const start_date = item?.end_date
                    ? moment
                        .unix(item?.end_date as number)
                        .add(1, "days")
                        .unix()
                    : undefined;

                  const end_date = start_date
                    ? moment
                        .unix(start_date as number)
                        .add(
                          lastOrder?.rent_period,
                          lastOrder?.rent_type?.toLowerCase() as any
                        )
                        .subtract(1, "days")
                        .unix()
                    : undefined;

                  const original_price = contextStorage
                    ? calPrice(
                        {
                          ...item,
                          rent_type: lastOrder?.rent_type,
                          rent_period: lastOrder?.rent_period,
                        },
                        contextStorage
                      )
                    : 0;
                  return {
                    id: item?.id,
                    item_type: item?.item_type,
                    item_id: item?.item_id,
                    item_name: {
                      en: contextStorage?.code,
                      zh: contextStorage?.code,
                      cn: contextStorage?.code,
                    } as MultiLangText,
                    rent_period: lastOrder?.rent_period,
                    rent_type: lastOrder?.rent_type,
                    start_date: start_date,
                    end_date: end_date,
                    original_price: original_price,
                    final_price: original_price,
                    status: item?.status,
                    sort: i,
                  };
                default: {
                  const chargeType = contextBranch?.charge_types?.find(
                    (chargeType) => chargeType.id === item?.item_id
                  );

                  return {
                    id: item?.id,
                    item_name: chargeType?.charge_name,
                    item_id: item?.item_id,
                    charge_type_id: chargeType?.id,
                    charge_mode: chargeType?.charge_mode,
                    quantize: chargeType?.quantize,
                    unit_price: chargeType?.unit_price,
                    unit: chargeType?.unit,
                    start_date: item?.end_date
                      ? moment
                          .unix(item?.end_date as number)
                          .add(1, "days")
                          .unix()
                      : undefined,
                    quantity_start: item?.quantity_end ?? 0,
                    item_type: item?.item_type,
                    original_price: item?.original_price ?? 0,
                    status: item?.status,
                    sort: i,
                  };
                }
              }
            })
          );
        }
      }
    }
  };

  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t(RentMode.ContinueRent)}
          </ModalHeader>
          <div className="ml-3">
            <h5 className="mt-3 font-13">
              {i18n.t("Rental Location Number")} - {contextStorage?.code}
            </h5>
          </div>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <div className="mt-4">
                    <h5 className="font-size-14 mb-4">{i18n.t("Rent Mode")}</h5>
                    <Row>
                      {steps.map((e, i) => {
                        return (
                          <Col
                            key={i}
                            md={4}
                            className="btn"
                            onClick={() => {
                              setCurrentStep(i);
                            }}
                          >
                            <div
                              className={`avatar-sm rounded-circle  align-self-center mini-stat-icon float-left `}
                            >
                              <span
                                className={`avatar-title rounded-circle  ${
                                  currentStep === steps.length - 1 && formValid
                                    ? "bg-success"
                                    : currentStep === i
                                    ? "bg-primary"
                                    : isFinished(i)
                                    ? "bg-success"
                                    : "bg-light "
                                } `}
                              >
                                {i + 1}
                              </span>
                            </div>
                            <div className="float-left h-100 ml-4 align-items-center ">
                              <h5 className="pt-3 h-100 justify-content-center align-items-center  font-size-14 mb-1 text-dark">
                                {i18n.t(e)}
                              </h5>
                            </div>
                            {steps?.length - 1 !== i && (
                              <i
                                className={`bx bx-right-arrow-alt  pt-3 font-size-22  ${
                                  currentStep === i
                                    ? "text-primary"
                                    : isFinished(i)
                                    ? "text-success"
                                    : "text-light"
                                }`}
                              ></i>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <StorageRentContractPanel
              rentMode={RentMode.ContinueRent}
              totalSteps={steps.length}
              // panel={step}
              step={1}
              onSubmit={async (e: any) => {
                console.log(e);
                // setCustomer(e?.customer);
                // setStartDate(e?.start_date);
                // if (e?.id) {
                //   setContract(e as Contract);
                // } else {
                //   setRequestBody(
                //     requestBody.set(steps[0], {
                //       ...e,
                //       storage_id: contextStorage?.id,
                //       branch_id: contextBranch?.id,
                //     })
                //   );
                // }
                setContract(e as Contract);
                setCurrentStep(0 + 1);
                setProcess([...process, 0]);
              }}
              contract={props.contract}
              customer={customer!}
              branch={contextBranch!}
              storage={contextStorage}
              mode={props.mode}
              isFinished={isFinished(0)}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 0));
              }}
              open={currentStep === 0}
              startDate={startDate ?? moment().unix()}
              onContractConflit={(e: any) => {}}
              // orderDate={moment().unix()}
              // paidItems={[]}
            ></StorageRentContractPanel>
            <StorageRentOrderPanel
              // rentMode={RentMode.ContinueRent}
              totalSteps={steps.length}
              // panel={step}
              step={2}
              onSubmit={async (
                e: CreateDepositOrderRequest | CreateOrderRequest
              ) => {
                console.log(e);
                if (contextBranch?.id && contextStorage?.id) {
                  setRequestBody(
                    requestBody.set(steps[1], {
                      ...(e as CreateOrderRequest),
                      // contract_id: contract?.id,
                      customer_id: customer?.id,
                    })
                  );
                }

                setProcess([...process, 1]);
              }}
              contract={props.contract ?? contract}
              customer={customer!}
              branch={contextBranch!}
              storage={contextStorage}
              // mode={props.mode}
              isFinished={isFinished(1)}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 1));
              }}
              open={currentStep === 1}
              // startDate={moment().unix()}
              orderDate={moment().unix()}
              paidItems={paidItems}
            ></StorageRentOrderPanel>
            {currentStep === steps.length - 1 && (
              <BxButton
                loading={submitButtonBlock}
                disabled={!formValid || submitButtonBlock}
                className="btn  "
                type="button"
                color="success"
                onClick={submit}
              >
                {i18n.t("Submit")}
              </BxButton>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRentNewOrder);
