
import axios from 'axios';
import { BoxappBackend } from './boxapp_helper'
import { Data, ResponseResult } from 'src/api/reponseResult';

class BoxappNotificationBackend extends BoxappBackend {

  updateNotifications = async (feature: any): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/notifications`, feature, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        // "Content-Type": "application/json",
        validateStatus: (status) => {
          return status ? true : false;
        },
      });
      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  readNotifications = async (param: {
    ids: string[]
  }): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/notifications/read`, {
        ids: param?.ids?.join(',')
      }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        // "Content-Type": "application/json",
        validateStatus: (status) => {
          return status ? true : false;
        },
      });
      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  getNotifications = async (query: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/notifications`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: query
        });
      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };


}

let _BoxappNotificationBackend: BoxappNotificationBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappNotificationBackend = () => {
  if (!_BoxappNotificationBackend) {
    _BoxappNotificationBackend = new BoxappNotificationBackend();
  }
  return _BoxappNotificationBackend;
};

export { getBoxappNotificationBackend };
