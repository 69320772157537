import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  FormGroup,
  Label,
  Alert,
  Input,
  InputGroup,
  Col,
  Row,
  Card,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  CardBody,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import ReactTooltip from "react-tooltip";
import { BxButton } from "../../Button";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment, { min } from "moment-timezone";
import { withTranslation, useTranslation } from "react-i18next";
// import UseAPI, { SUCCESS } from "../../../api";
// import { API_ACTION } from "../../../api/action";
import "./storageRent.scss";
import { Wallet } from "src/models/wallet";
import { Customer } from "src/models/customer";
import { getBoxappCustomerBackend } from "src/helpers/boxapp_customer_helper";
import { Order } from "src/models/order";
import { Storage } from "src/models/storage";
import { Tr, Th, Td } from "react-super-responsive-table";
import { MultiLangText } from "src/models/multiLangText";
// import WalletPaidItem from "src/pages/Orders/components/WalletPaidItem";
import StoragePaidItem from "src/pages/Orders/components/StoragePaidItem";
import ChargeTypePaidItem from "src/pages/Orders/components/ChargeTypePaidItem";
import { Branch } from "src/models/branch";
import { PaidItem } from "src/models/paidItem";
import { ChargeType } from "src/models/chargeType";
import { Contract } from "src/models/contract";
import { InvoiceType } from "src/models/invoice";
import Decimal from "decimal.js";
import { OrderType } from "src/util/orderType";
import {
  CreateDepositOrderRequest,
  CreateOrderRequest,
} from "src/helpers/boxapp_order_helper";
import MiscellaneousPaidItem from "src/pages/Orders/components/MiscellaneousPaidItem";
import { ChargeMode } from "src/util/chargeMode";

export type StorageRentOrderPanelProps = {
  branch: Branch;
  storage?: Storage;
  paidItems: PaidItem[];
  customer?: Customer;
  step: number;
  isFinished: boolean;
  onCancel: () => void;
  open: boolean;
  onSubmit: (order: CreateDepositOrderRequest | CreateOrderRequest) => void;
  orderDate: number;
  totalSteps: number;
  contract?: Contract;
};

const StorageRentOrderPanel = (props: StorageRentOrderPanelProps) => {
  const { i18n } = useTranslation();
  const [remarks, setRemarks] = useState<string>();
  const [invoiceRemarks, setInvoiceRemarks] = useState<string>();
  const [itemDropDown, setItemDropDown] = useState<boolean>(false);
  const [wallet, setWallet] = useState<Wallet>();
  const [selectedChargeTypePaidItem, setSelectedChargeTypePaidItem] =
    useState<PaidItem>();
  const [selectedStoragePaidItem, setSelectedStoragePaidItem] =
    useState<PaidItem>();
  const [selectedMiscellaneousPaidItem, setSelectedMiscellaneousPaidItem] =
    useState<PaidItem>();
  const [modalStoragePaidItem, setModalStoragePaidItem] =
    useState<boolean>(false);
  const [modalMiscellaneousPaidItem, setModalMiscellaneousPaidItem] =
    useState<boolean>(false);
  const [modalChargeTypePaidItem, setModalChargeTypePaidItem] =
    useState<boolean>(false);
  const [discountAmount, setDiscountAmount] = useState<Decimal>();
  const [paidItems, setPaidItems] = useState<PaidItem[]>([]);
  const [paidItemTotalOriginalPrice, setPaidItemTotalOriginalPrice] =
    useState<Decimal>();
  const [walletItemTotalOriginalPrice, setWalletItemTotalOriginalPrice] =
    useState<Decimal>();
  const [errorMessage, setErrorMessage] = useState<Map<string, string>>(
    new Map()
  );
  useEffect(() => {
    if (props.paidItems) {
      setPaidItems(props.paidItems);
    }
  }, [props.paidItems]);

  useEffect(() => {
    if (paidItems?.length === 0 && props.open) {
      setModalStoragePaidItem(true);
    }
    setPaidItemTotalOriginalPrice(
      paidItems
        ?.filter(
          (paidItem) =>
            paidItem?.item_type !== "WALLET" &&
            paidItem?.item_type !== "PREPAID"
        )
        ?.reduce((a, v) => (a = a.plus(v?.original_price ?? 0)), new Decimal(0))
    );
    setWalletItemTotalOriginalPrice(
      paidItems
        ?.filter((paidItem) => paidItem?.item_type === "WALLET")
        ?.reduce(
          (a, v) => (a = a.plus(new Decimal(v.original_price ?? 0).abs())),
          new Decimal(0)
        )
    );
  }, [paidItems]);

  const closeModal = () => {
    setModalStoragePaidItem(false);
    setModalChargeTypePaidItem(false);
    setModalMiscellaneousPaidItem(false);
    // setModalWalletPaidItem(false);
  };

  useEffect(() => {
    if (props.customer) {
      if (props.customer?.wallet) {
        setWallet(props.customer?.wallet);
      } else {
        if (props.customer?.id) {
          getBoxappCustomerBackend()
            .getWallets(props.customer?.id, {})
            .then((res) => {
              setWallet(res?.wallet);
            });
        }
      }
    }
  }, [props.customer]);

  const title = () => {
    if (props.isFinished) {
      const storage = paidItems?.find((x) => x?.item_type === "STORAGE");
      if (storage) {
        return `(${moment
          .unix(Number(storage?.start_date))
          ?.format("YYYY-MM-DD")} - ${moment
          .unix(Number(storage?.end_date))
          ?.format("YYYY-MM-DD")})`;
      } else {
        //find some other item with start date and end date
        const item = paidItems?.find((x) => x?.start_date && x?.end_date);
        if (item) {
          return `(${moment
            .unix(Number(item?.start_date))
            ?.format("YYYY-MM-DD")} - ${moment
            .unix(Number(item?.end_date))
            ?.format("YYYY-MM-DD")})`;
        } else {
          const item = paidItems?.find((x) => x?.start_date);
          return `(${moment
            .unix(Number(item?.start_date))
            ?.format("YYYY-MM-DD")}  `;
        }
      }
    }
    return "";
  };

  const paidItemDetail = (item: PaidItem) => {
    switch (item?.item_type) {
      case "STORAGE":
        return `${item.rent_period ?? ""} ${
          i18n.t(item.rent_type ?? "") ?? ""
        }`;
      default:
        if (item.quantize) {
          if (item?.charge_mode === ChargeMode.ACCUMLATIVE) {
            return `${new Decimal(item.quantity_end ?? 0)
              .minus(item.quantity_start ?? 0)
              .toNumber()}`;
          }
          if (item?.charge_mode === ChargeMode.STAND_ALONE) {
            return `${Number(item.quantity_start)} ${item.unit}`;
          }
        }

        return "";
    }
  };
  const onSubmit = () => {
    setErrorMessage(new Map());
    const _storage = paidItems?.find((x) => x?.item_type === "STORAGE");
    if (!_storage) {
      errorMessage.set("paid_items", i18n.t("storage cannot be empty"));

      setErrorMessage(errorMessage);
      return;
    }
    let hasError = false;
    const items: PaidItem[] = paidItems?.map((item) => {
      if (!item?.item_type) {
        if (item?.id) {
          errorMessage.set(item?.id, i18n.t("item type cannot be empty"));
          hasError = true;
        }
      }
      item.discount_amount = 0;
      // item.original_price = CalRound(item, item?.original_price);
      item.final_price =
        Number(item?.original_price) - Number(item?.discount_amount);

      switch (item?.item_type) {
        case "STORAGE":
          item.discount_amount = Number(discountAmount);
          item.item = {
            ...props.storage,
            branch: undefined,
            categories: undefined,
            charge_types: undefined,
            contract_id: undefined,
            contract: undefined,
            customer_id: undefined,
            features: undefined,
            master_contract_id: undefined,
            order: undefined,
            order_id: undefined,
            rent_end_date: undefined,
            rent_records: undefined,
          } as Storage;
          break;
        default:
          //all other charge types
          if (item?.charge_mode === ChargeMode.ACCUMLATIVE) {
            item.quantity_start = Number(item?.quantity_start);
            item.quantity_end = Number(item?.quantity_end);
          } else if (item?.charge_mode === ChargeMode.STAND_ALONE) {
            item.quantity_start = Number(
              item?.quantity_start ?? item?.default_quantity
            );
          }
          const chargeType = props.branch?.charge_types?.find(
            (chargeType) => chargeType.id === item?.item_id
          );
          item.item = {
            ...item,
            ...chargeType,
            order_id: undefined,
            order_type: undefined,
            invoice_type: undefined,
            invoice_id: undefined,
            item: undefined,
            paid_amount: undefined,
            need_to_pay: undefined,
            rent_type: undefined,
            rent_period: undefined,
            roundUp: undefined,
            roundDown: undefined,
          } as ChargeType;
      }

      return item as PaidItem;
    });
    if (items?.length === 0) {
      errorMessage.set("paid_items", i18n.t("paid items cannot be empty"));
      hasError = true;

      setErrorMessage(errorMessage);
      return;
    }
    if (hasError) {
      errorMessage.set("paid_items", i18n.t("some item has error"));

      setErrorMessage(errorMessage);
      return;
    }

    const finalPrice = paidItemTotalOriginalPrice?.minus(discountAmount ?? 0);
    if (finalPrice?.isNegative()) {
      errorMessage.set("finalPrice", i18n.t("price cannot be less than 0"));

      setErrorMessage(errorMessage);
      return;
    }

    setErrorMessage(new Map());
    props.onSubmit({
      order_type: "Rent",
      order_date: props.orderDate,
      start_date: _storage?.start_date,
      end_date: _storage?.end_date,
      actual_start_date: _storage?.start_date,
      actual_end_date: _storage?.end_date,
      rent_type: _storage?.rent_type,
      rent_period: _storage?.rent_period,
      customer: props.customer,
      original_price: paidItemTotalOriginalPrice?.toNumber(),
      discount_amount: discountAmount?.toNumber(),
      final_price: finalPrice?.toNumber(),
      remarks: remarks ?? "",
      invoice_remarks: invoiceRemarks ?? "",
      branch_id: props.branch?.id,
      paid_items: items,
      contract_id: props.contract?.id,
    } as CreateDepositOrderRequest | CreateOrderRequest);
  };
  return (
    <div>
      <ChargeTypePaidItem
        // order={props.order}
        branch={props.branch}
        invoiceType={InvoiceType.Payment}
        orderType={OrderType?.Rent}
        modal={modalChargeTypePaidItem}
        item={selectedChargeTypePaidItem}
        onSuccess={async (paidItem) => {
          if (selectedChargeTypePaidItem) {
            setPaidItems(
              paidItems?.map((item, i) =>
                item.id === paidItem.id
                  ? {
                      ...paidItem,
                      sort: i,
                    }
                  : item
              )
            );
          } else {
            setPaidItems([
              ...paidItems,
              {
                ...paidItem,
                sort: paidItems?.length,
                id: crypto.randomUUID(),
              },
            ]);
          }
          setSelectedChargeTypePaidItem(undefined);
          closeModal();
        }}
        onClose={closeModal}
        onFailed={() => {
          closeModal();
          // props.onSuccess();
        }}
      />
      {props.storage && (
        <StoragePaidItem
          // order={props.order}
          branch={props.branch}
          storage={props.storage}
          invoiceType={InvoiceType.Payment}
          orderType={OrderType?.Rent}
          modal={modalStoragePaidItem}
          item={selectedStoragePaidItem}
          onSuccess={async (paidItem) => {
            if (selectedStoragePaidItem) {
              setPaidItems(
                paidItems?.map((item, i) =>
                  item.id === paidItem.id
                    ? {
                        ...paidItem,
                        sort: i,
                      }
                    : item
                )
              );
            } else {
              setPaidItems([
                ...paidItems,
                {
                  ...paidItem,
                  sort: paidItems?.length,
                  id: crypto.randomUUID(),
                },
              ]);
            }
            setSelectedStoragePaidItem(undefined);
            closeModal();
          }}
          onClose={closeModal}
          onFailed={() => {
            closeModal();
          }}
        />
      )}
      <MiscellaneousPaidItem
        // order={props.order}
        branch={props.branch}
        invoiceType={InvoiceType.Payment}
        orderType={OrderType?.Rent}
        modal={modalMiscellaneousPaidItem}
        item={selectedMiscellaneousPaidItem}
        onSuccess={async (paidItem) => {
          if (selectedMiscellaneousPaidItem) {
            setPaidItems(
              paidItems?.map((item, i) =>
                item.id === paidItem.id
                  ? {
                      ...paidItem,
                      sort: i,
                    }
                  : item
              )
            );
          } else {
            setPaidItems([
              ...paidItems,
              {
                ...paidItem,
                sort: paidItems?.length,
                id: crypto.randomUUID(),
              },
            ]);
          }
          setSelectedMiscellaneousPaidItem(undefined);
          closeModal();
        }}
        onClose={closeModal}
        onFailed={() => {
          closeModal();
          // props.onSuccess();
        }}
      />
      <AvForm
        hidden={!props.open}
        action="#"
        id={"CollapseForm" + props.step}
        title={`${i18n.t("Order")} ${title()}`}
        onCancel={props.onCancel}
        open={props.open}
      >
        <Card outline color="light" className="border">
          <CardBody>
            <Row>
              <Col xs="12">
                {`${i18n.t("Order Created Date")}: ${moment
                  .unix(props.orderDate)
                  .format("YYYY-MM-DD HH:mm:ss")}`}
              </Col>
            </Row>

            <Row className="mt-3 mb-2">
              <Col xs="10">
                <Label className="font-size-13 font-weight-bold" htmlFor="">
                  {i18n.t("Paid Items")}
                </Label>
              </Col>
              <Col xs="12">
                <Alert
                  color="success"
                  className="alert-dismissible fade show  mb-4 pt-2 font-size-13"
                  role="alert"
                >
                  <i className="far fa-smile mr-2"> </i>
                  {`1.${i18n.t(
                    "If renewing, the data from the previous rental agreement will be automatically entered. Please remember to update each piece of information."
                  )}`}

                  <p>
                    <i className="far fa-smile mr-2"> </i>
                    {`2.${i18n.t(
                      "The sorting is based on the current sequence of order placement. Rest assured, you can still change your sorting in the order details after placing an order."
                    )}`}
                  </p>
                </Alert>
              </Col>
              <Col xs={12}>{errorMessage.get("paid_items")?.toString()}</Col>
            </Row>
            <Row className="mb-4">
              <Col xs={12}>
                <table className="table table-striped ">
                  <thead>
                    <Tr>
                      <Th className="co-name text-center">
                        {i18n.t("Paid Items")}
                      </Th>
                      <Th className="co-name">{i18n.t("Items Price")}</Th>
                      <Th className="co-name">{i18n.t("Detail")}</Th>
                      <Th className="co-name">{i18n.t("Start Date")}</Th>
                      <Th className="co-name">{i18n.t("End Date")}</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {paidItems?.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          xl="2"
                          lg="2"
                          sm="4"
                          xs="6"
                          className={`col-sm-1 col-md-1 mt-1 mr-1`}
                        >
                          <Td>
                            <i
                              className="btn text-primary mdi mdi-file-document-edit-outline font-size-18 "
                              onClick={() => {
                                switch (item?.item_type) {
                                  case "STORAGE":
                                    setSelectedStoragePaidItem(item);
                                    setModalStoragePaidItem(true);

                                    break;
                                  case "MISCELLANEOUS":
                                    setSelectedMiscellaneousPaidItem(item);
                                    setModalMiscellaneousPaidItem(true);
                                    break;
                                  default:
                                    setSelectedChargeTypePaidItem(item);
                                    setModalChargeTypePaidItem(true);
                                    break;
                                }
                              }}
                            ></i>
                            <i
                              className="btn text-primary mdi mdi-trash-can-outline font-size-18  "
                              onClick={async () => {
                                setPaidItems(
                                  paidItems?.filter(
                                    (paidItem) => paidItem?.id !== item?.id
                                  )
                                );
                              }}
                            ></i>
                            {item?.item_type === "STORAGE"
                              ? `${i18n.t(item?.item_type)} - ${
                                  item?.item_name?.[
                                    i18n.language as keyof MultiLangText
                                  ] ?? ""
                                }`
                              : item?.item_type === "WALLET"
                              ? `${i18n.t("Wallet Paid")}`
                              : item?.item_type === "PREPAID"
                              ? `${i18n.t("Extra Amount")}`
                              : item?.item_type === "REPAY"
                              ? `${i18n.t("Repay")}`
                              : item?.item_name?.[
                                  i18n.language as keyof MultiLangText
                                ] ?? ""}
                            {(item?.id &&
                              errorMessage.get(item?.id)?.toString()) ??
                              ""}
                          </Td>
                          <Td>{item.final_price}</Td>
                          <Td>{paidItemDetail(item)}</Td>
                          <Td>
                            {item.start_date
                              ? moment
                                  .unix(item.start_date as number)
                                  ?.format("YYYY-MM-DD")
                              : "N/A"}
                          </Td>
                          <Td>
                            {item.end_date
                              ? moment
                                  .unix(item.end_date as number)
                                  ?.format("YYYY-MM-DD")
                              : "N/A"}
                          </Td>
                        </Tr>
                      );
                    })}
                    <Tr>
                      <Td>
                        <ButtonDropdown
                          className="text-primary"
                          isOpen={itemDropDown}
                          toggle={() => setItemDropDown(!itemDropDown)}
                        >
                          <DropdownToggle caret color="link">
                            <i className="mdi mdi-plus font-size-18  "></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setSelectedChargeTypePaidItem(undefined);
                                setModalChargeTypePaidItem(true);
                              }}
                            >
                              {i18n.t("Other Charge Items")}
                            </DropdownItem>

                            <DropdownItem
                              onClick={async () => {
                                setSelectedStoragePaidItem(undefined);
                                setModalStoragePaidItem(true);
                              }}
                            >
                              {i18n.t("Storage Supplement Item")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={async () => {
                                setSelectedMiscellaneousPaidItem(undefined);
                                setModalMiscellaneousPaidItem(true);
                              }}
                            >
                              {i18n.t("Miscellaneous Item")}
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                  </tbody>
                </table>
              </Col>
            </Row>

            <Row>
              <Col xs="6">
                <FormGroup className="mb-4">
                  <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                    "Order"
                  )}${i18n.language === "en" ? " " : ""}${i18n.t(
                    "Orignal Price"
                  )}`}</Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text font-size-13 font-weight-bold">
                        $
                      </span>
                    </div>
                    <Input
                      className="bg-light text-gray bg-opacity-10 font-size-15"
                      type="number"
                      maxLength={8}
                      name="finalPrice"
                      value={paidItemTotalOriginalPrice?.toNumber()}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>
                    {i18n.t("Discount Amount")}
                    <a data-tip data-for="tips2">
                      {i18n.t("ⓘ")}
                    </a>
                    <ReactTooltip id="tips2" effect="solid">
                      <span>
                        {i18n.t("Discount count on Rental Location.")}
                      </span>
                    </ReactTooltip>
                  </Label>
                  <AvField
                    name="discountAmount"
                    placeholder={i18n.t("Discount Amount")}
                    type="number"
                    errorMessage={i18n.t("invalid Discount Amount")}
                    className="form-control"
                    id="validatiomDiscountAmount"
                    value={discountAmount ?? 0}
                    onChange={(e: any) => {
                      if (!isNaN(Number(e.target.value))) {
                        setDiscountAmount(new Decimal(e.target.value ?? 0));
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <FormGroup className="mb-4">
                  <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                    "Order"
                  )}${i18n.language === "en" ? " " : ""}${i18n.t(
                    "Final Price"
                  )}`}</Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text font-size-13 font-weight-bold">
                        $
                      </span>
                    </div>
                    <Input
                      disabled
                      className="bg-light text-gray bg-opacity-10 font-weight-bold font-size-15"
                      type="number"
                      maxLength={8}
                      name="finalPrice"
                      value={paidItemTotalOriginalPrice
                        ?.minus(walletItemTotalOriginalPrice ?? 0)
                        ?.toNumber()}
                    />
                  </div>
                  <div className="error">
                    {errorMessage.get("final_price")?.toString() ?? ""}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="border-top border-5 pt-4 md-4">
              <Col xs="6" className="d-flex flex-column align-items-start pe-2">
                <Label className="font-12 mb-2">
                  {`${i18n.t("Order")} & ${i18n.t("Invoice")}${
                    i18n.language === "en" ? " " : ""
                  }${i18n.t("Remarks")}`}
                </Label>
                <Input
                  type="textarea"
                  id="orderRemarks"
                  maxLength={500}
                  name="remarks"
                  onChange={(e) => {
                    setRemarks(e.target.value ?? "");
                    setInvoiceRemarks(e.target.value ?? "");
                  }}
                  value={remarks}
                  className="w-100 p-2 bg-white border scrollable-container"
                  style={{ height: "100px", resize: "none", overflow: "auto" }}
                />
              </Col>

              <Col
                xs="2"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    setRemarks(
                      props?.storage?.rent_records?.[0]?.order?.remarks
                    );
                    setInvoiceRemarks(
                      props?.storage?.rent_records?.[0]?.order?.remarks
                    );
                  }}
                >
                  <i className="fas fa-arrow-left">{i18n.t("COPY")}</i>
                </Button>
              </Col>
              <Col xs="4" className="d-flex flex-column align-items-start ps-2">
                <Label className="font-12 mb-2">
                  {i18n.t("Last Order Remarks")}
                </Label>
                <div
                  className="w-100 p-2 bg-light border"
                  style={{ height: "100px", overflowY: "auto" }}
                >
                  <div
                    className="font-size-12 scrollable-container"
                    style={{ whiteSpace: "pre-wrap", overflowY: "auto" }}
                  >
                    {props?.storage?.rent_records?.[0]?.order?.remarks}
                  </div>
                </div>
              </Col>
            </Row>

            <BxButton
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
              x
              onClick={() => {
                onSubmit();
              }}
            >
              {i18n.t(`${props.totalSteps > 1 ? "Next Step" : "Submit"}`)}
            </BxButton>
            {props.totalSteps === 1 && (
              <BxButton
                type="submit"
                color="secondary"
                className="mt-3 ml-2"
                form={"CollapseForm" + props.step}
                onClick={props.onCancel}
              >
                {i18n.t("Close")}
              </BxButton>
            )}
          </CardBody>
        </Card>
      </AvForm>
    </div>
  );
};

export default withTranslation()(StorageRentOrderPanel);
