import React, { lazy, useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { BxButton } from "../../Button";
import moment from "moment-timezone";
import { withTranslation, useTranslation } from "react-i18next";

// Redux

import "./storageRent.scss";

import { useStorageContext } from "../../../context/StorageProviderContext";

import { useBranchContext } from "../../../context/BranchProviderContext";

import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { getBoxappContractBackend } from "../../../helpers/boxapp_contract_helper";
import {
  CreateDepositOrderRequest,
  CreateOrderRequest,
  getBoxappOrderBackend,
} from "../../../helpers/boxapp_order_helper";

import { Storage } from "src/models/storage";
import { Contract } from "src/models/contract";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { Customer } from "src/models/customer";
import { RentMode, RentPanelSteps } from "./StorageRent";
import StorageRentContractPanel from "./StorageRentContractPanel";
import StorageRentOrderPanel from "./StorageRentOrderPanel";
import StorageRentDepositPanel from "./StorageRentDepositPanel";

export interface StorageRentNewMasterContractProps {
  mode: string;
  onSuccess: (action: string, storage: Storage) => void;
  onClose: () => void;
  modal: boolean;
  customer: Customer;
}

const StorageRentNewMasterContract = (
  props: StorageRentNewMasterContractProps
) => {
  const { i18n } = useTranslation();
  const [process, setProcess] = useState<number[]>([]);
  const { contextStorage } = useStorageContext();
  const [customer, setCustomer] = useState<Customer>(props.customer);
  const [contract, setContract] = useState<Contract>();
  const { contextBranch } = useBranchContext();
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  const [requestBody, setRequestBody] = useState<Map<string, any>>(new Map());
  const steps = [
    RentPanelSteps.Contract,
    RentPanelSteps.Order,
    RentPanelSteps.Deposit,
  ];
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { showModal } = useGlobalModalContext();
  const [contractConflit, setContractConflit] = useState<boolean>();

  const isFinished = useCallback(
    (i: number) => process?.indexOf(i) > -1,
    [process]
  );
  const formValid = useMemo(() => {
    if (steps.length === 1) {
      return true;
    }
    return process.length >= steps.length;
  }, [process]);

  const togglemodal = () => {
    props.onClose();
  };

  const submit = async () => {
    setSubmitButtonBlock(true);
    const interval = 2000;
    const timeoutId = setTimeout(() => {
      setSubmitButtonBlock(false);
      clearTimeout(timeoutId);
    }, interval);
    try {
      let _contract = contract;
      if (!_contract) {
        const res = await getBoxappContractBackend().create(
          requestBody.get(RentPanelSteps.Contract)
        );
        setContract(res?.contract);
        _contract = res?.contract;
      }
      if (contextBranch?.id && contextStorage?.id && _contract) {
        const res = await getBoxappStorageBackend().rentStorage(
          contextBranch?.id,
          contextStorage?.id,
          {
            ...requestBody.get(RentPanelSteps.Order),
            contract_id: _contract?.id,
          }
        );
        const depositReq = requestBody.get(RentPanelSteps.Deposit);
        if (depositReq) {
          await getBoxappOrderBackend().createDepositOrder({
            ...depositReq,
            start_date: _contract?.start_date,
            end_date: _contract?.end_date,
            rent_type: _contract?.rent_type,
            rent_period: _contract?.rent_period,
            master_contract_id: _contract?.master_contract_id,
          });
        }

        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: i18n.t("rent rental location Success"),
          messageType: "success",
          message: i18n.t("rent rental location Success"),
        });
        if (props.onSuccess && res.storage)
          props.onSuccess(props.mode, res.storage);
      }
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("rent rental location failed"),
        messageType: "error",
        message: errorMsg,
      });
    }
  };

  return !contextStorage ? null : (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.modal}
        role="dialog"
        backdrop="static"
        autoFocus={true}
        centered={true}
        className="rent"
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t(RentMode.NewMasterContract)}
          </ModalHeader>
          <div className="ml-3">
            <h5 className="mt-3 font-13">
              {i18n.t("Rental Location Number")} - {contextStorage?.code}
            </h5>
          </div>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <div className="mt-4">
                    <h5 className="font-size-14 mb-4">{i18n.t("Rent Mode")}</h5>
                    <Row>
                      {steps.map((e, i) => {
                        return (
                          <Col
                            key={i}
                            md={4}
                            className="btn"
                            onClick={() => {
                              setCurrentStep(i);
                            }}
                          >
                            <div
                              className={`avatar-sm rounded-circle  align-self-center mini-stat-icon float-left `}
                            >
                              <span
                                className={`avatar-title rounded-circle  ${
                                  currentStep === steps.length - 1 && formValid
                                    ? "bg-success"
                                    : currentStep === i
                                    ? "bg-primary"
                                    : isFinished(i)
                                    ? "bg-success"
                                    : "bg-light "
                                } `}
                              >
                                {i + 1}
                              </span>
                            </div>
                            <div className="float-left h-100 ml-4 align-items-center ">
                              <h5 className="pt-3 h-100 justify-content-center align-items-center  font-size-14 mb-1 text-dark">
                                {i18n.t(e)}
                              </h5>
                            </div>
                            {steps?.length - 1 !== i && (
                              <i
                                className={`bx bx-right-arrow-alt  pt-3 font-size-22  ${
                                  currentStep === i
                                    ? "text-primary"
                                    : isFinished(i)
                                    ? "text-success"
                                    : "text-light"
                                }`}
                              ></i>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <StorageRentContractPanel
              rentMode={RentMode.NewMasterContract}
              totalSteps={steps.length}
              // panel={step}
              step={1}
              onSubmit={async (e: any) => {
                setCustomer(e?.customer);
                if (e?.id) {
                  setContract(e as Contract);
                } else {
                  setRequestBody(
                    requestBody.set(steps[0], {
                      ...e,
                      storage_id: contextStorage?.id,
                      branch_id: contextBranch?.id,
                    })
                  );
                }
                setCurrentStep(0 + 1);
                setProcess([...process, 0]);
              }}
              contract={contract}
              customer={customer!}
              branch={contextBranch!}
              storage={contextStorage}
              mode={props.mode}
              isFinished={isFinished(0)}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 0));
              }}
              open={currentStep === 0}
              startDate={moment().unix()}
              onContractConflit={(e: any) => {
                console.log(e);
                setContractConflit(e ? true : false);
              }}
              // orderDate={moment().unix()}
              // paidItems={[]}
            ></StorageRentContractPanel>
            <StorageRentOrderPanel
              // rentMode={RentMode.NewMasterContract}
              totalSteps={steps.length}
              // panel={step}
              step={2}
              onSubmit={async (
                e: CreateDepositOrderRequest | CreateOrderRequest
              ) => {
                if (contextBranch?.id && contextStorage?.id) {
                  setRequestBody(
                    requestBody.set(steps[1], {
                      ...e,
                      // contract_id: contract?.id,
                      customer_id: customer?.id,
                    })
                  );
                }

                setCurrentStep(1 + 1);
                setProcess([...process, 1]);
              }}
              contract={contract}
              customer={customer!}
              branch={contextBranch!}
              storage={contextStorage}
              // mode={props.mode}
              isFinished={isFinished(1)}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 1));
              }}
              open={currentStep === 1}
              // startDate={moment().unix()}
              orderDate={moment().unix()}
              paidItems={[]}
            ></StorageRentOrderPanel>
            <StorageRentDepositPanel
              // rentMode={RentMode.NewMasterContract}
              // totalSteps={steps.length}
              // panel={step}
              step={3}
              onSubmit={async (e: any) => {
                if (e?.deposit_require) {
                  setRequestBody(
                    requestBody.set(steps[2], {
                      ...e,
                      customer_id: customer?.id,
                      // start_date: contract?.start_date,
                      // end_date: contract?.end_date,
                      // rent_type: contract?.rent_type,
                      // rent_period: contract?.rent_period,
                      storage_id: contextStorage?.id,
                      branch_id: contextBranch?.id,
                      // master_contract_id: contract?.master_contract_id,
                    })
                  );
                }
                setProcess([...process, 2]);
              }}
              // contract={contract}
              // customer={customer!}
              branch={contextBranch}
              storage={contextStorage}
              // mode={props.mode}
              isFinished={isFinished(2)}
              onCancel={() => {
                setProcess(process.filter((p) => p !== 2));
              }}
              open={currentStep === 2}
              // startDate={moment().unix()}
              // orderDate={moment().unix()}
              // paidItems={[]}
            ></StorageRentDepositPanel>
            <Row>
              <Col xs={12}>
                {currentStep === steps.length - 1 && (
                  <BxButton
                    loading={submitButtonBlock}
                    disabled={!formValid || submitButtonBlock}
                    className="btn"
                    type="button"
                    color="success"
                    onClick={submit}
                  >
                    {i18n.t("Submit")}
                  </BxButton>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageRentNewMasterContract);
