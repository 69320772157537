import { useCallback, useReducer } from "react";
import i18n from "../i18n.ts";
import { MODAL_TYPES } from "../components/Modal/ModalProps";
import { useGlobalModalContext } from "../context/GlobalModalContext";
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";

import { getBoxappOrderBackend } from "../helpers/boxapp_order_helper";
// import { getBoxappOrderItemBackend } from "../helpers/boxapp_order_item_helper";
import { getBoxappOrderFollowBackend } from "../helpers/boxapp_order_follow_helper";
import { getBoxappContractBackend } from "../helpers/boxapp_contract_helper";
import { getBoxappDashboardBackend } from "../helpers/boxapp_dashboard_helper";
import { getBoxappAuthBackend } from "../helpers/boxapp_auth_helper";
import { getBoxappAccountBackend } from "../helpers/boxapp_account_helper";
import { getBoxappChargeTypeBackend } from "../helpers/boxapp_charge_type_helper";
import { getBoxappExpendTypeBackend } from "../helpers/boxapp_expend_type_helper";
import { getBoxappBranchBackend } from "../helpers/boxapp_branch_helper";
import { getBoxappCompanyBackend } from "../helpers/boxapp_company_helper";
import { getBoxappRoleBackend } from "../helpers/boxapp_role_helper";
import { getBoxappCategoryBackend } from "../helpers/boxapp_category_helper";
import { getBoxappFeatureBackend } from "../helpers/boxapp_feature_helper";
import { getBoxappCustomerBackend } from "../helpers/boxapp_customer_helper";
import { getBoxappLeadBackend } from "../helpers/boxapp_lead_helper";
import { getBoxappStorageBackend } from "../helpers/boxapp_storage_helper";
import { getBoxappSingleProductBackend } from "../helpers/boxapp_single_product_helper";
import { getBoxappConsumableProductBackend } from "../helpers/boxapp_consumable_product_helper";
import { getBoxappReportBackend } from "../helpers/boxapp_report_helper";
import { getBoxappInvoiceBackend } from "../helpers/boxapp_invoice_helper";
import { getBoxappPaymentBackend } from "../helpers/boxapp_payment_helper";

import { getBoxappCustomFieldBackend } from "../helpers/boxapp_custom_field_helper";
import { getBoxappNotificationBackend } from "../helpers/boxapp_notification_helper";
import { getBoxappAppSettingBackend } from "../helpers/boxapp_app_setting_helper";
import { getBoxappTemplateBackend } from "../helpers/boxapp_template_helper";
import { useHistory } from "react-router-dom";
import { duration } from "../util/duration";
import { store } from "../util/storage";
import { API_ACTION } from "./action";

export const FETCHING = `FETCHING`;
export const SUCCESS = `SUCCESS`;
export const ERROR = `ERROR`;
// interface state {
//   status?: string;
//   type: string;
//   response?: any;
// }
export const INIT_STATE = {
  type: "",
  status: undefined,
  response: null,
};
export const fetching = () => ({ type: FETCHING });
export const success = (response) => ({ type: SUCCESS, response });
export const error = (response) => ({ type: ERROR, response });

export const reducer = (state = INIT_STATE, { type, response }) => {
  switch (type) {
    case FETCHING:
      return { ...INIT_STATE, status: FETCHING };
    case SUCCESS:
      return { ...state, status: SUCCESS, response };
    case ERROR:
      return { ...state, status: ERROR, response };
    default:
      return state;
  }
};

const UseAPI = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const { showModal, loading, setLoading } = useGlobalModalContext();
  const BoxappTemplateBackend = getBoxappTemplateBackend();
  const BoxappOrderBackend = getBoxappOrderBackend();
  const BoxappOrderFollowBackend = getBoxappOrderFollowBackend();
  const BoxappPaymentBackend = getBoxappPaymentBackend();
  const BoxappContractBackend = getBoxappContractBackend();
  // const BoxappOrderItemBackend = getBoxappOrderItemBackend();
  const BoxappDashboardBackend = getBoxappDashboardBackend();
  const BoxappAuthBackend = getBoxappAuthBackend();
  const BoxappAccountBackend = getBoxappAccountBackend();
  const BoxappRoleBackend = getBoxappRoleBackend();
  const BoxappCategoryBackend = getBoxappCategoryBackend();
  const BoxappFeatureBackend = getBoxappFeatureBackend();
  const BoxappCustomerBackend = getBoxappCustomerBackend();
  const BoxappLeadBackend = getBoxappLeadBackend();
  const BoxappStorageBackend = getBoxappStorageBackend();
  const BoxappSingleProductBackend = getBoxappSingleProductBackend();
  const BoxappConsumableProductBackend = getBoxappConsumableProductBackend();

  const BoxappBranchBackend = getBoxappBranchBackend();
  const BoxappChargeTypeBackend = getBoxappChargeTypeBackend();
  const BoxappExpendTypeBackend = getBoxappExpendTypeBackend();
  const BoxappCompanyBackend = getBoxappCompanyBackend();
  const BoxappReportBackend = getBoxappReportBackend();
  const BoxappInvoiceBackend = getBoxappInvoiceBackend();
  const BoxappCustomFieldBackend = getBoxappCustomFieldBackend();
  const BoxappNotificationBackend = getBoxappNotificationBackend();
  const BoxappAppSettingBackend = getBoxappAppSettingBackend();

  const getPayloadValue = async (key, ...payload) => {
    const [first, ...last] = payload;
    return first[key];
  };
  const APICallStack = [];
  const makeRequest = useCallback(async (apiAction, ...payload) => {
    let successMsg = null;
    const apiTimeOut = () => {
      APICallStack.pop();
      if (APICallStack.length === 0) {
        setLoading(false);
      } else {
        APICallStack.splice(0, APICallStack.length);
        setLoading(false);
      }
    };
    try {
      dispatch(fetching());
      setLoading(true);
      if (APICallStack.findIndex((item) => item === apiAction) === -1) {
        APICallStack.push(apiAction);
      } else {
        return;
      }

      switch (apiAction) {
        case API_ACTION.API_GET_MASTER_CONTRACTS:
          {
            const { master_contracts } =
              await BoxappContractBackend.getMasterContracts(payload);
            if (master_contracts) {
              dispatch(
                success({
                  masterContracts: master_contracts,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get master contract failed";
            }
          }
          break;
        case API_ACTION.API_UPLOAD_MASTER_CONTRACTS_ATTACHMENT: {
          const { attachment } = await BoxappContractBackend.uploadAttachment(
            ...payload
          );
          if (attachment) {
            dispatch(success({ attachment: attachment }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
            successMsg = "upload success";
          } else {
            throw "upload failed";
          }
          break;
        }
        case API_ACTION.API_GET_MASTER_CONTRACTS_ATTACHMENTS: {
          const { attachments } = await BoxappContractBackend.getAttachments(
            ...payload
          );
          if (attachments) {
            dispatch(success({ attachments: attachments }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get attachments failed";
          }
          break;
        }
        case API_ACTION.API_GET_CONTRACTS:
          {
            const { contracts } = await BoxappContractBackend.getContracts(
              ...payload
            );
            if (contracts) {
              dispatch(
                success({
                  contracts: contracts,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get contract failed";
            }
          }
          break;

        case API_ACTION.API_GET_MASTER_CONTRACT:
          {
            const { master_contract } =
              await BoxappContractBackend.getMasterContract(...payload);
            if (master_contract) {
              dispatch(
                success({
                  masterContract: master_contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get master contract failed";
            }
          }
          break;
        case API_ACTION.API_GET_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.getContract(
              ...payload
            );
            if (contract) {
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get contract failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.create(...payload);
            if (contract) {
              successMsg = "create contract success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create contract failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.update(...payload);
            if (contract) {
              successMsg = "contract update success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create update failed";
            }
          }
          break;

        case API_ACTION.API_EXTEND_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.extend(...payload);
            if (contract) {
              successMsg = "extend contract success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "extend contract failed";
            }
          }
          break;
        case API_ACTION.API_END_CONTRACT:
          {
            const { contract } = await BoxappContractBackend.end(...payload);
            if (contract) {
              successMsg = "end contract success";
              dispatch(
                success({
                  contract: contract,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "end contract failed";
            }
          }
          break;
        case API_ACTION.API_GET_PAYMENTS:
          {
            const { payments } = await BoxappPaymentBackend.getPayments(
              ...payload
            );
            if (payments) {
              dispatch(
                success({
                  payments: payments,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get payments failed";
            }
          }
          break;
        case API_ACTION.API_GET_PAYMENT:
          {
            const { payment } = await BoxappPaymentBackend.getPayment(
              ...payload
            );
            if (payment) {
              dispatch(
                success({
                  payment: payment,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get payment failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_ORDER_FOLLOW:
          {
            const { follow } = await BoxappOrderFollowBackend.createOrderFollow(
              ...payload
            );
            if (follow) {
              successMsg = "create order follow success";
              dispatch(
                success({
                  follow: follow,
                })
              );
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create order follow failed";
            }
          }
          break;
        case API_ACTION.API_GET_ORDERS_FOLLOWS:
          {
            const { follows, orders } =
              await BoxappOrderFollowBackend.getBranchOrdersFollows(...payload);
            if (follows && orders) {
              dispatch(success({ follows: follows, orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get order follows failed";
            }
          }
          break;
        case API_ACTION.API_GET_ORDER_FOLLOWS:
          {
            const { follows } = await BoxappOrderFollowBackend.getOrderFollows(
              ...payload
            );
            if (follows) {
              dispatch(success({ follows: follows }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get order follows failed";
            }
          }
          break;
        case API_ACTION.API_GET_DEPOSIT_ORDER:
        case API_ACTION.API_GET_ORDER:
          if (payload[1] && payload[1] === "Deposit") {
            const { deposit_order } = await BoxappOrderBackend.getOrder(
              ...payload
            );
            if (deposit_order) {
              dispatch(success({ depositOrder: deposit_order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get depositorder failed";
            }
          } else {
            const { order } = await BoxappOrderBackend.getOrder(...payload);
            if (order) {
              dispatch(success({ order: order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get order failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_DEPOSIT:
          {
            const { deposit_order } =
              await BoxappOrderBackend.createDepositOrder(...payload);
            if (deposit_order) {
              dispatch(success({ deposit_order: deposit_order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get deposit orders failed";
            }
          }
          break;
        case API_ACTION.API_GET_ORDERS:
          {
            const { orders } = await BoxappOrderBackend.getOrders(...payload);
            if (orders) {
              dispatch(success({ orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get orders failed";
            }
          }
          break;

        case API_ACTION.API_GET_ORDER_EVENTS:
          {
            //id
            const { events } = await BoxappOrderBackend.getOrderEvents(
              ...payload
            );
            if (events) {
              dispatch(success({ events: events }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get events failed";
            }
          }
          break;
        case API_ACTION.API_VOID_ORDER:
          {
            const { order } = await BoxappOrderBackend.void(...payload);
            if (order) {
              successMsg = "void order success";
              dispatch(success({ order: order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "void order failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_ORDER:
          {
            const { order, deposit_order } =
              await BoxappOrderBackend.updateOrder(...payload);
            if (payload[2]) {
              if (payload[2] === "Deposit") {
                if (deposit_order) {
                  dispatch(success({ deposit_order: deposit_order }));
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                  successMsg = "Update Order Success";
                } else {
                  throw "update order failed";
                }
              } else {
                if (order) {
                  dispatch(success({ order: order }));
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                  successMsg = "Update Order Success";
                } else {
                  throw "update order failed";
                }
              }
            } else {
              if (order) {
                dispatch(success({ order: order }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                successMsg = "Update Order Success";
              } else {
                throw "update order failed";
              }
            }
          }
          break;
        case API_ACTION.API_CREATE_PAYMENT:
          {
            const { payment, storage } = await BoxappOrderBackend.createPayment(
              ...payload
            );
            if (payment) {
              dispatch(success({ payment: payment, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update payment success";
            } else {
              throw "update payment failed";
            }
          }
          break;
        case API_ACTION.API_VOID_PAYMENT:
          {
            const { payment, storage } = await BoxappInvoiceBackend.voidPayment(
              ...payload
            );
            if (payment) {
              dispatch(success({ payment: payment, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update payment success";
            } else {
              throw "update payment failed";
            }
          }
          break;

        case API_ACTION.API_UPDATE_PAYMENT:
          {
            const { payment, storage } =
              await BoxappInvoiceBackend.updatePayment(...payload);
            if (payment) {
              dispatch(success({ payment: payment, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update payment success";
            } else {
              throw "update payment failed";
            }
          }
          break;
        case API_ACTION.API_UPLOAD_ORDER_ATTACHMENT: {
          const { attachment } = await BoxappOrderBackend.uploadAttachment(
            ...payload
          );
          if (attachment) {
            dispatch(success({ attachment: attachment }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
            successMsg = "upload success";
          } else {
            throw "upload failed";
          }
          break;
        }
        case API_ACTION.API_GET_ORDER_ATTACHMENTS: {
          const { attachments } = await BoxappOrderBackend.getOrderAttachments(
            ...payload
          );
          if (attachments) {
            dispatch(success({ attachments: attachments }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get attachments failed";
          }
          break;
        }
        case API_ACTION.API_DOWNLOAD_RECEIPTES: {
          const receipt = await BoxappOrderBackend.getReceipt(...payload);
          if (receipt) {
            dispatch(success({ receipt: receipt }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "download failed";
          }
          break;
        }

        case API_ACTION.API_DOWNLOAD_ORDER_ATTACHMENT:
          {
            const attachment = await BoxappOrderBackend.getOrderAttachment(
              ...payload
            );
            if (attachment) {
              dispatch(success({ download: attachment }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "download failed";
            }
          }
          break;
        // case API_ACTION.API_CREATE_ORDER_ITEMS:
        //   {
        //     const { paid_items } = await BoxappOrderItemBackend.createOrderItem(
        //       ...payload
        //     );
        //     if (paid_items) {
        //       dispatch(success({ paidItems: paid_items }));
        //       APICallStack.pop();
        //       if (APICallStack.length === 0) {
        //         setLoading(false);
        //       }
        //       successMsg = "create order items success";
        //     } else {
        //       throw "create order items failed";
        //
        //     }
        //   }
        //   break;
        // case API_ACTION.API_GET_PAID_ITEMS:
        //   {
        //     const { paid_items } = await BoxappOrderItemBackend.getOrderItems(
        //       ...payload
        //     );
        //     if (paid_items) {
        //       dispatch(success({ paidItems: paid_items }));
        //       APICallStack.pop();
        //       if (APICallStack.length === 0) {
        //         setLoading(false);
        //       }
        //       successMsg = "get order items success";
        //     } else {
        //       throw "get order items failed";
        //
        //     }
        //   }
        //   break;
        case API_ACTION.API_GET_INVOICE:
          {
            const { invoice, order } = await BoxappInvoiceBackend.getInvoice(
              ...payload
            );
            if (invoice && order) {
              dispatch(success({ invoice: invoice, order: order }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "download failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_INVOICE:
          {
            const { invoice } = await BoxappInvoiceBackend.createInvoice(
              ...payload
            );
            if (invoice) {
              dispatch(success({ invoice: invoice }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "Create Invoice Success";
            } else {
              throw "create invoice failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_INVOICE:
          {
            const { invoice } = await BoxappInvoiceBackend.updateInvoice(
              ...payload
            );
            if (invoice) {
              dispatch(success({ invoice: invoice }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "Update Invoice Success";
            } else {
              throw "update invoice failed";
            }
          }
          break;
        case API_ACTION.API_CANCEL_INVOICE:
          {
            const { invoice } = await BoxappInvoiceBackend.cancelInvoice(
              ...payload
            );
            if (invoice) {
              dispatch(success({ invoice: invoice }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "cancel invoice success";
            } else {
              throw "cancel invoice failed";
            }
          }
          break;
        case API_ACTION.API_VOID_INVOICE:
          {
            const { invoice } = await BoxappInvoiceBackend.voidInvoice(
              ...payload
            );
            if (invoice) {
              dispatch(success({ invoice: invoice }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "void invoice success";
            } else {
              throw "void invoice failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_ACCOUNT:
          const { account, detail, message } =
            await BoxappAccountBackend.createAccount(...payload);
          if (account) {
            store.removeItem("accounts");
            successMsg = "create account success";
            dispatch(success({ account: account }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            console.log(detail, message);
            throw "create account failed";
          }

          break;
        case API_ACTION.API_UPDATE_ACCOUNT:
          {
            const { account } = await BoxappAccountBackend.updateAccount(
              ...payload
            );
            if (account) {
              store.removeItem("accounts");
              successMsg = "update account success";
              dispatch(success({ account: account }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update account failed";
            }
          }
          break;
        case API_ACTION.API_GET_ROLES:
          {
            if (!payload) {
              const cache = store.getWithExpiry("roles");
              if (cache) {
                dispatch(success({ roles: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }
            const { roles } = await BoxappRoleBackend.getRoles(...payload);
            if (roles) {
              if (!payload) {
                store.setWithExpiry("roles", roles, duration.minute * 6);
              }
              dispatch(success({ roles: roles }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get roles failed";
            }
          }
          break;
        case API_ACTION.API_GET_PERMISSIONS:
          {
            const cache = store.getWithExpiry("permissions");
            if (cache) {
              dispatch(success({ permissions: cache }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              return;
            }
            const { permissions } = await BoxappRoleBackend.getPermissions();
            if (permissions) {
              store.setWithExpiry(
                "permissions",
                permissions,
                duration.minute * 6
              );
              dispatch(success({ permissions: permissions }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get permissions failed";
            }
          }
          break;
        case API_ACTION.API_EDIT_ROLE:
          {
            const { role } = await BoxappRoleBackend.updateRole(...payload);
            if (role) {
              store.removeItem("roles");
              dispatch(success({ role: role }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update role Success";
            } else {
              throw "update role  failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_ROLE:
          {
            const { role } = await BoxappRoleBackend.createRole(...payload);
            if (role) {
              store.removeItem("roles");
              dispatch(success({ role: role }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "create role Success";
            } else {
              throw "create role  failed";
            }
          }
          break;
        case API_ACTION.API_GET_ACCOUNTS:
          {
            if (!payload) {
              const cache = store.getWithExpiry("accounts");
              if (cache) {
                dispatch(success({ accounts: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }

            const { accounts } = await BoxappAccountBackend.getAccounts(
              ...payload
            );
            if (accounts) {
              if (!payload) {
                store.setWithExpiry("accounts", accounts, duration.minute * 6);
              }

              dispatch(success({ accounts: accounts }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get accounts failed";
            }
          }
          break;
        case API_ACTION.API_GET_MFA_QRCODE: {
          const { QRCode } = await BoxappAuthBackend.mfaQRCode(...payload);
          dispatch(
            success({
              QRCode: QRCode,
            })
          );
          APICallStack.pop();
          if (APICallStack.length === 0) {
            setLoading(false);
          }
          break;
        }
        case API_ACTION.API_MFA_SEND: {
          const firebaseAuth = getAuth();
          firebaseAuth.tenantId = process.env.REACT_APP_TENANT_ID;
          const { confirm } = await signInWithPhoneNumber(
            firebaseAuth,
            ...payload,
            window.recaptchaVerifier
          );
          console.log(confirm);
          break;
        }
        case API_ACTION.API_MFA_LOGIN: {
          const { token, authUser } = await BoxappAuthBackend.mfaLogin(
            ...payload
          );
          if (token) {
            localStorage.setItem("app-namespace", authUser.app);
            localStorage.setItem("token", token.access_token);
            const { permissions } =
              await BoxappAuthBackend.getAuthPermissions();
            i18n.setDefaultNamespace(authUser.app);
            dispatch(
              success({
                token: token,
                authUser: {
                  ...authUser,
                  role: {
                    ...authUser.role,
                    permissions: permissions,
                  },
                },
              })
            );
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "token failed";
          }
          break;
        }
        case API_ACTION.API_LOGIN:
          try {
            const firebaseAuth = getAuth();
            firebaseAuth.tenantId = process.env.REACT_APP_TENANT_ID;

            const { user } = await signInWithEmailAndPassword(
              firebaseAuth,
              ...payload
            );
            if (user) {
              try {
                const { token, authUser, MFA, auth_token } =
                  await BoxappAuthBackend.exchangeToken(
                    user.stsTokenManager.accessToken
                  );
                if (token) {
                  localStorage.setItem("app-namespace", authUser.app);
                  localStorage.setItem("token", token.access_token);
                  const { permissions } =
                    await BoxappAuthBackend.getAuthPermissions();

                  i18n.setDefaultNamespace(authUser.app);

                  dispatch(
                    success({
                      token: token,
                      authUser: {
                        ...authUser,
                        role: {
                          ...authUser?.role,
                          permissions: permissions,
                        },
                      },
                    })
                  );
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                } else {
                  dispatch(
                    success({
                      MFA: MFA,
                      authToken: auth_token,
                    })
                  );
                }
              } catch (err) {
                console.log(err);
                throw "invalid access";
              }
            }
          } catch (err) {
            console.log(err);
            if (!err?.data) {
              err.data = {};
            }
            throw "Login failed";
          }

          // const { token } = await BoxappAuthBackend.loginUser(...payload);
          // if (token) {
          //   dispatch(success({ token: token }));
          //   APICallStack.pop()
          //   if (APICallStack.length === 0) {
          //     setLoading(false)
          //   }
          // } else {
          //   throw "Login failed"
          //   throw (err)
          // }
          break;

        case API_ACTION.API_LOGOUT:
          try {
            const auth = getAuth();
            await signOut(auth);
            localStorage.clear();
            dispatch(success({ token: null, authUser: null }));
          } catch (e) {
            throw "Logout failed";
          }
          // await BoxappAuthBackend.logout();

          break;
        case API_ACTION.API_GET_COMPANY_INFO:
          {
            const cache = store.getWithoutExpiry("company");
            if (cache) {
              dispatch(success({ company: cache }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              return;
            }
            const { company } = await BoxappCompanyBackend.getCompany(
              ...payload
            );
            if (company) {
              store.setWithoutExpiry("company", company);
              dispatch(success({ company: company }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get company failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_COMPANY_INFO:
          {
            const { company } = await BoxappCompanyBackend.updateCompany(
              ...payload
            );
            if (company) {
              store.removeItem("company");
              dispatch(success({ company: company }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              successMsg = "update company Success";
            } else {
              throw "update company failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_COMPANY_TANDC:
          {
            const { company } = await BoxappCompanyBackend.updateCompanyTandC(
              ...payload
            );
            if (company) {
              store.removeItem("company");
              dispatch(success({ company: company }));
              successMsg = "update company Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update company failed";
            }
          }
          break;

        case API_ACTION.API_UPLOAD_COMPANY_LOGO:
          {
            store.removeItem("company");
            const { company } = await BoxappCompanyBackend.updateCompanyLogo(
              ...payload
            );
            if (company) {
              successMsg = "Upload Success";
              dispatch(success({ company: company }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update company logo failed";
            }
          }
          break;
        case API_ACTION.API_GET_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.getBranch(...payload);
            if (branch) {
              dispatch(success({ branch: branch }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get branch failed";
            }
          }
          break;
        case API_ACTION.API_GET_BRANCHES:
          {
            const cache = store.getWithExpiry("branches");
            if (cache) {
              dispatch(success({ branches: cache }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
              return;
            }
            const { branches } = await BoxappBranchBackend.getBranches();
            if (branches) {
              store.setWithExpiry("branches", branches, duration.second * 2);
              dispatch(success({ branches: branches }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get branches failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.createBranch(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "create branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create branch failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_BRANCH_TANDC:
          {
            const { branch } = await BoxappBranchBackend.updateBranchTandC(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "update branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update branch failed";
            }
          }
          break;
        case API_ACTION.API_DISABLE_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.disableBranch(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "update branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update branch failed";
            }
          }
          break;
        case API_ACTION.API_SORT_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.sortBranch(...payload);
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "sort branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "sort branch failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_BRANCH:
          {
            const { branch } = await BoxappBranchBackend.updateBranch(
              ...payload
            );
            if (branch) {
              store.removeItem("branches");
              dispatch(success({ branch: branch }));
              successMsg = "update branch Success";
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update branch failed";
            }
          }
          break;
        case API_ACTION.API_UPLOAD_BRANCH_LOGO: {
          const { branch } = await BoxappBranchBackend.uploadBranchLogo(
            ...payload
          );
          if (branch) {
            dispatch(success({ branch: branch }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
            successMsg = "upload success";
          } else {
            throw "upload failed";
          }
          break;
        }
        case API_ACTION.API_GET_AUTH_USER: {
          // if (authUser) {
          //   dispatch(success({ authUser: authUser }));
          //    APICallStack.pop()
          if (APICallStack.length === 0) {
            setLoading(false);
          }
          //   return authUser;
          // }
          const { account } = await BoxappAuthBackend.getAuthenticatedUser(
            ...payload
          );
          if (account) {
            dispatch(success({ authUser: account }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get auth user failed";
          }
          break;
        }
        case API_ACTION.API_GET_AUTH_PERMISSIONS: {
          const { permissions } = await BoxappAuthBackend.getAuthPermissions();
          if (permissions) {
            dispatch(success({ permissions: permissions }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get permissions failed";
          }
          break;
        }
        case API_ACTION.API_CHANGE_PASSWORD:
          {
            const { token } = await BoxappAuthBackend.changePassword(
              ...payload
            );
            if (token) {
              successMsg = "Update password Success";
              dispatch(success({ token: token }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "Change Password  failed";
            }
          }
          break;
        case API_ACTION.API_GET_CATEGORIES:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key =
              branch_id + "_categories" + (is_filter ? "_filter" : "");
            if (branch_id) {
              const cache = store.getWithExpiry(key);
              if (cache) {
                dispatch(success({ categories: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }

            const { categories } = await BoxappCategoryBackend.getCategories(
              ...payload
            );
            if (categories) {
              store.setWithExpiry(key, categories, duration.minute * 2);
              dispatch(success({ categories: categories }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get categories failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_CATEGORY:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key =
              branch_id + "_categories" + (is_filter ? "_filter" : "");
            const { category } = await BoxappCategoryBackend.createCategory(
              ...payload
            );
            if (category) {
              store.removeItem(key);
              successMsg = "create category Success";
              dispatch(success({ category: category }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create category failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_CATEGORY:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key =
              branch_id + "_categories" + (is_filter ? "_filter" : "");
            const { category } = await BoxappCategoryBackend.updateCategory(
              ...payload
            );
            if (category) {
              store.removeItem(key);
              successMsg = "update category Success";
              dispatch(success({ category: category }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update category failed";
            }
          }
          break;
        case API_ACTION.API_GET_FEATURES:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const is_filter = await getPayloadValue("is_filter", ...payload);
            const key = branch_id + "_features" + (is_filter ? "_filter" : "");
            if (branch_id) {
              const cache = store.getWithExpiry(key);
              if (cache) {
                dispatch(success({ features: cache }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
                return;
              }
            }

            const { features } = await BoxappFeatureBackend.getFeatures(
              ...payload
            );
            if (features) {
              store.setWithExpiry(key, features, duration.minute * 2);
              dispatch(success({ features: features }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get features failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_FEATURE:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const filter = await getPayloadValue("is_filter", ...payload);
            const key = branch_id + "_features" + (filter ? "_filter" : "");
            const { feature } = await BoxappFeatureBackend.createFeature(
              ...payload
            );
            if (feature) {
              store.removeItem(key);
              successMsg = "create feature Success";
              dispatch(success({ feature: feature }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create feature failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_FEATURE:
          {
            const branch_id = await getPayloadValue("branch_id", ...payload);
            const filter = await getPayloadValue("is_filter", ...payload);
            const key = branch_id + "_features" + (filter ? "_filter" : "");
            const { feature } = await BoxappFeatureBackend.updateFeature(
              ...payload
            );
            if (feature) {
              store.removeItem(key);
              successMsg = "update feature Success";
              dispatch(success({ feature: feature }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update feature failed";
            }
          }
          break;
        case API_ACTION.API_GET_CUSTOMER:
          {
            const { customer } = await BoxappCustomerBackend.getCustomer(
              ...payload
            );
            if (customer) {
              dispatch(success({ customer: customer }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get customer failed";
            }
          }
          break;

        case API_ACTION.API_UPLOAD_CUSTOMER_ATTACHMENT: {
          const { attachment } = await BoxappCustomerBackend.uploadAttachment(
            ...payload
          );
          if (attachment) {
            dispatch(success({ attachment: attachment }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
            successMsg = "upload success";
          } else {
            throw "upload failed";
          }
          break;
        }
        case API_ACTION.API_GET_CUSTOMER_ATTACHMENTS: {
          const { attachments } = await BoxappCustomerBackend.getAttachments(
            ...payload
          );
          if (attachments) {
            dispatch(success({ attachments: attachments }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get attachments failed";
          }
          break;
        }
        case API_ACTION.API_GET_CUSTOMER_RENT_RECORDS:
          {
            const { rent_records } =
              await BoxappCustomerBackend.getCustomerRentRecords(...payload);
            if (rent_records) {
              dispatch(success({ rentRecords: rent_records }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get rent records failed";
            }
          }
          break;

        case API_ACTION.API_GET_CUSTOMERS:
          {
            const { customers } = await BoxappCustomerBackend.getCustomers(
              ...payload
            );
            if (customers) {
              dispatch(success({ customers: customers }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get customers failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_CUSTOMER:
          {
            const { customer } = await BoxappCustomerBackend.createCustomer(
              ...payload
            );
            if (customer) {
              successMsg = "create customer Success";
              dispatch(success({ customer: customer }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create customer failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_CUSTOMER:
          {
            const { customer } = await BoxappCustomerBackend.updateCustomer(
              ...payload
            );
            if (customer) {
              successMsg = "Update Customer Success";
              dispatch(success({ customer: customer }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update customer failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_WALLET:
          {
            const { wallet } = await BoxappCustomerBackend.createWallet(
              ...payload
            );
            if (wallet) {
              const customerId = payload?.[0];
              if (customerId) {
                store.removeItem(`${customerId}/wallets`);
              }
              successMsg = "update wallet Success";
              dispatch(success({ wallet: wallet }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update wallet failed";
            }
          }
          break;
        case API_ACTION.API_TOP_UP_WALLET:
          {
            const { wallet } = await BoxappCustomerBackend.topup(...payload);
            if (wallet) {
              successMsg = "topup wallet Success";
              dispatch(success({ wallet: wallet }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "topup wallet failed";
            }
          }
          break;
        case API_ACTION.API_WITHDRAW_WALLET:
          {
            const { wallet } = await BoxappCustomerBackend.withdraw(...payload);
            if (wallet) {
              successMsg = "withdraw wallet Success";
              dispatch(success({ wallet: wallet }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "withdraw wallet failed";
            }
          }
          break;
        case API_ACTION.API_GET_WALLETS:
          {
            const { wallets } = await BoxappCustomerBackend.getWallets(
              ...payload
            );
            if (wallets) {
              // successMsg = "update wallets Success";
              dispatch(success({ wallets: wallets }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update wallets failed";
            }
          }
          break;

        case API_ACTION.API_GET_TRANSACTIONS:
          {
            const { transactions } =
              await BoxappCustomerBackend.getTransactions(...payload);
            if (transactions) {
              // successMsg = "update transactions Success";
              dispatch(success({ transactions: transactions }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update transactions failed";
            }
          }
          break;

        case API_ACTION.API_GET_LEAD:
          {
            const { lead } = await BoxappLeadBackend.getLead(...payload);
            if (lead) {
              dispatch(success({ lead: lead }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get lead failed";
            }
          }
          break;
        case API_ACTION.API_GET_LEADS:
          {
            const { leads } = await BoxappLeadBackend.getLeads(...payload);
            if (leads) {
              dispatch(success({ leads: leads }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get leads failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_LEAD:
          {
            const { lead } = await BoxappLeadBackend.createLead(...payload);
            if (lead) {
              successMsg = "create lead Success";
              dispatch(success({ lead: lead }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create lead failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_LEAD:
          {
            const { lead } = await BoxappLeadBackend.updateLead(...payload);
            if (lead) {
              successMsg = "update lead Success";
              dispatch(success({ lead: lead }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update customer failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_LEAD_CALL:
          {
            const { lead_call } = await BoxappLeadBackend.createLeadCall(
              ...payload
            );
            if (lead_call) {
              successMsg = "create lead call Success";
              dispatch(success({ leadCall: lead_call }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create lead call failed";
            }
          }
          break;
        case API_ACTION.API_GET_STORAGES:
          {
            const branch_id = payload?.[0];
            const key = branch_id + "_storages";
            if (branch_id) {
              if (payload?.[1]?.query) {
                const cache = store.getWithExpiry(key);
                if (cache) {
                  dispatch(success({ storages: cache }));
                  APICallStack.pop();
                  if (APICallStack.length === 0) {
                    setLoading(false);
                  }
                  return;
                }
              }

              const { storages } = await BoxappStorageBackend.getStorages(
                ...payload
              );
              if (storages) {
                if (payload?.[1]?.query) {
                  store.setWithExpiry(key, storages, duration.minute * 2);
                }
                dispatch(success({ storages: storages }));
                APICallStack.pop();
                if (APICallStack.length === 0) {
                  setLoading(false);
                }
              } else {
                throw "get storages failed";
              }
            }
          }
          break;
        case API_ACTION.API_GET_STORAGE:
          {
            //id
            const { storage } = await BoxappStorageBackend.getStorage(
              ...payload
            );
            if (storage) {
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get rental location failed";
            }
          }
          break;
        case API_ACTION.API_GET_STORAGE_RENT_RECORDS:
          {
            //id
            const { rent_records } =
              await BoxappStorageBackend.getStorageRentRecords(...payload);
            if (rent_records) {
              dispatch(success({ rent_records: rent_records }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get rent records failed";
            }
          }
          break;
        case API_ACTION.API_GET_STORAGE_EVENTS:
          {
            //id
            const { events } = await BoxappStorageBackend.getStorageEvents(
              ...payload
            );
            if (events) {
              dispatch(success({ events: events }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get events failed";
            }
          }
          break;
        case API_ACTION.API_GET_STORAGE_ORDERS:
          {
            //id
            const { orders } = await BoxappStorageBackend.getStorageOrders(
              ...payload
            );
            if (orders) {
              dispatch(success({ orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get orders failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.createStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "create rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create rental location failed";
            }
          }
          break;
        case API_ACTION.API_CREATE_STORAGES:
          {
            const { storages, storage } =
              await BoxappStorageBackend.createStorage(...payload);
            if (storages || storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "create storages Success";
              dispatch(success({ storages: storages, storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create storages failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.updateStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "update rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update rental location failed";
            }
          }
          break;

        case API_ACTION.API_UPDATE_STORAGES:
          {
            const { storages } = await BoxappStorageBackend.bulkUpdateStorage(
              ...payload
            );
            if (storages) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "update storages Success";
              dispatch(success({ storages: storages }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update storages failed";
            }
          }
          break;
        case API_ACTION.API_RENT_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.rentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              successMsg = "rent rental location Success";
              dispatch(success({ storage: storage }));
              if (branch_id) {
                const key = branch_id + "_storages";
                store?.removeItem(key);
              }
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "rent rental location failed";
            }
          }
          break;
        case API_ACTION.API_RENT_CANCEL:
          {
            const { storage } = await BoxappStorageBackend.cancel(...payload);
            if (storage) {
              const branch_id = payload?.[0];
              console.log(payload);
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "rent cancel Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "rent cancel failed";
            }
          }
          break;
        case API_ACTION.API_RENT_CONFIRM:
          {
            const { storage } = await BoxappStorageBackend.confirmRentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "rent confirm Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "rent confrim failed";
            }
          }
          break;
        case API_ACTION.API_RENT_EXTEND:
          {
            const { storage } = await BoxappStorageBackend.extendRentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "rent extend Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "rent extend failed";
            }
          }
          break;
        case API_ACTION.API_TERMINATE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.terminateRentStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "terminate rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "terminate rental location failed";
            }
          }
          break;
        case API_ACTION.API_MAINTENANCE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.maintenanceStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "Maintenance rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "Maintenance rental location Failed";
            }
          }
          break;
        case API_ACTION.API_RELEASE_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.releaseStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "Resume rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "Resume rental location failed";
            }
          }
          break;
        case API_ACTION.API_RESUME_STORAGE:
          {
            const { storage } = await BoxappStorageBackend.resumeStorage(
              ...payload
            );
            if (storage) {
              const branch_id = payload?.[0];
              const key = branch_id + "_storages";
              if (branch_id) {
                store?.removeItem(key);
              }
              successMsg = "Resume rental location Success";
              dispatch(success({ storage: storage }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "Resume rental location failed";
            }
          }
          break;
        case API_ACTION.API_REPORT_STORAGES: {
          const { report } = await BoxappReportBackend.storagesReport(
            ...payload
          );
          if (report) {
            dispatch(success({ report: report }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get report failed";
          }
          break;
        }
        case API_ACTION.API_CREATE_SINGLE_PRODUCT: {
          const { single_product } =
            await BoxappSingleProductBackend.createSingleProduct(...payload);
          if (single_product) {
            successMsg = "create item Success";
            dispatch(success({ singleProduct: single_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "create single product failed";
          }
          break;
        }
        case API_ACTION.API_UPDATE_SINGLE_PRODUCT: {
          const { single_product } =
            await BoxappSingleProductBackend.updateSingleProduct(...payload);
          if (single_product) {
            successMsg = "Update Item Success";
            dispatch(success({ singleProduct: single_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update single product failed";
          }
          break;
        }
        case API_ACTION.API_GET_SINGLE_PRODUCTS: {
          const { single_products } =
            await BoxappSingleProductBackend.getSingleProducts(...payload);
          if (single_products) {
            dispatch(success({ singleProducts: single_products }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get single products failed";
          }
          break;
        }
        case API_ACTION.API_GET_SINGLE_PRODUCT: {
          const { single_product } =
            await BoxappSingleProductBackend.updateSingleProduct(...payload);
          if (single_product) {
            dispatch(success({ singleProduct: single_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get single product failed";
          }
          break;
        }

        case API_ACTION.API_CREATE_CONSUMABLE_PRODUCT: {
          const { consumable_product } =
            await BoxappConsumableProductBackend.createConsumableProduct(
              ...payload
            );
          if (consumable_product) {
            successMsg = "create item Success";
            dispatch(success({ consumableProduct: consumable_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "create consumable product failed";
          }
          break;
        }
        case API_ACTION.API_UPDATE_CONSUMABLE_PRODUCT: {
          const { consumable_product } =
            await BoxappConsumableProductBackend.updateConsumableProduct(
              ...payload
            );
          if (consumable_product) {
            successMsg = "Update Item Success";
            dispatch(success({ consumableProduct: consumable_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update consumable product failed";
          }
          break;
        }
        case API_ACTION.API_GET_CONSUMABLE_PRODUCTS: {
          const { consumable_products } =
            await BoxappConsumableProductBackend.getConsumableProducts(
              ...payload
            );
          if (consumable_products) {
            dispatch(success({ consumableProducts: consumable_products }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get consumable products failed";
          }
          break;
        }
        case API_ACTION.API_GET_CONSUMABLE_PRODUCT: {
          const { consumable_product } =
            await BoxappConsumableProductBackend.updateConsumableProduct(
              ...payload
            );
          if (consumable_product) {
            dispatch(success({ consumableProduct: consumable_product }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get consumable product failed";
          }
          break;
        }
        case API_ACTION.API_REPORT_CONTRACTS:
          {
            const { report } = await BoxappReportBackend.contractsReport(
              ...payload
            );
            if (report) {
              dispatch(success({ report: report }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get report failed";
            }
          }
          break;
        case API_ACTION.API_REPORT_ORDERS:
          {
            const { report } = await BoxappReportBackend.ordersReport(
              ...payload
            );
            if (report) {
              dispatch(success({ report: report }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get report failed";
            }
          }
          break;
        case API_ACTION.API_REPORT_MONTHLY:
          {
            const { report } = await BoxappReportBackend.monthlyReport(
              ...payload
            );
            if (report) {
              dispatch(success({ report: report }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get report failed";
            }
          }
          break;

        case API_ACTION.API_GET_DASHBOARD_MONTHLY_REVENUE:
          {
            const { monthly_revenue } =
              await BoxappDashboardBackend.monthlyRevenue();
            if (monthly_revenue) {
              dispatch(success({ monthlyRevenue: monthly_revenue }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // throw "get report failed"
              // throw (err)
            }
          }
          break;
        case API_ACTION.API_GET_DASHBOARD_LATEST_ORDER_STATUS:
          {
            const { order_status } =
              await BoxappDashboardBackend.latestOrdersStatus();
            if (order_status) {
              dispatch(success({ orderStatus: order_status }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // throw "get report failed"
              // throw (err)
            }
          }
          break;
        case API_ACTION.API_GET_DASHBOARD_LATEST_MONTHLY_ORDER:
          {
            const { orders } = await BoxappDashboardBackend.latestMonthOrders();
            if (orders) {
              dispatch(success({ orders: orders }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // throw "get report failed"
              // throw (err)
            }
          }
          break;
        case API_ACTION.API_GET_DASHBOARD_LATEST_STORAGE_STATUS:
          {
            const { storage_status } =
              await BoxappDashboardBackend.latestStorageStatus();
            if (storage_status) {
              dispatch(success({ storageStatus: storage_status }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              // throw "get report failed"
              // throw (err)
            }
          }
          break;
        case API_ACTION.API_GET_CUSTOM_FIELDS: {
          const { custom_fields } =
            await BoxappCustomFieldBackend.getCustomFields(...payload);
          if (custom_fields) {
            dispatch(success({ customFields: custom_fields }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get custom fields failed";
          }
          break;
        }
        case API_ACTION.API_CREATE_CUSTOM_FIELD: {
          const { custom_field } =
            await BoxappCustomFieldBackend.createCustomField(...payload);
          if (custom_field) {
            successMsg = "Create field Success";
            dispatch(success({ customField: custom_field }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "create custom fields failed";
          }
          break;
        }
        case API_ACTION.API_UPDATE_CUSTOM_FIELD: {
          const { custom_field } =
            await BoxappCustomFieldBackend.updateCustomField(...payload);
          if (custom_field) {
            successMsg = "Update field Success";
            dispatch(success({ customField: custom_field }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update custom fields failed";
          }
          break;
        }
        case API_ACTION.API_GET_CHARGE_TYPES: {
          const { charge_types } = await BoxappChargeTypeBackend.getChargeTypes(
            ...payload
          );
          if (charge_types) {
            dispatch(success({ chargeTypes: charge_types }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get charge types failed";
          }
          break;
        }
        case API_ACTION.API_CREATE_CHARGE_TYPE: {
          const { charge_type } =
            await BoxappChargeTypeBackend.createChargeType(...payload);
          if (charge_type) {
            successMsg = "Create field Success";
            dispatch(success({ chargeType: charge_type }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "create charge type failed";
          }
          break;
        }
        case API_ACTION.API_GET_CHARGE_TYPE_CATEGORIES: {
          const { charge_type_categories } =
            await BoxappChargeTypeBackend.getChargeTypeCategories(...payload);
          if (charge_type_categories) {
            dispatch(success({ chargeTypeCategories: charge_type_categories }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get charge type categories failed";
          }
          break;
        }
        case API_ACTION.API_CREATE_CHARGE_TYPE_CATEGORY: {
          const { charge_type_category } =
            await BoxappChargeTypeBackend.createChargeTypeCategory(...payload);
          if (charge_type_category) {
            successMsg = "Create field Success";
            store.removeItem("chargeTypeCategories");
            dispatch(success({ chargeTypeCategory: charge_type_category }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "create charge type category failed";
          }
          break;
        }

        case API_ACTION.API_UPDATE_CHARGE_TYPE: {
          const { charge_type } =
            await BoxappChargeTypeBackend.updateChargeType(...payload);
          if (charge_type) {
            successMsg = "Update field Success";
            dispatch(success({ chargeType: charge_type }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update charge type failed";
          }
          break;
        }
        case API_ACTION.API_GET_EXPEND_TYPES: {
          const { expend_types } = await BoxappExpendTypeBackend.getExpendTypes(
            ...payload
          );
          if (expend_types) {
            dispatch(success({ expendTypes: expend_types }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get charge types failed";
          }
          break;
        }
        case API_ACTION.API_CREATE_EXPEND_TYPE: {
          const { expend_type } =
            await BoxappExpendTypeBackend.createExpendType(...payload);
          if (expend_type) {
            successMsg = "Create field Success";
            dispatch(success({ expendType: expend_type }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "create charge type failed";
          }
          break;
        }
        case API_ACTION.API_GET_EXPEND_TYPE_CATEGORIES: {
          const { expend_type_categories } =
            await BoxappExpendTypeBackend.getExpendTypeCategories(...payload);
          if (expend_type_categories) {
            dispatch(success({ expendTypeCategories: expend_type_categories }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "get charge type categories failed";
          }
          break;
        }
        case API_ACTION.API_CREATE_EXPEND_TYPE_CATEGORY: {
          const { expend_type_category } =
            await BoxappExpendTypeBackend.createExpendTypeCategory(...payload);
          if (expend_type_category) {
            successMsg = "Create field Success";
            store.removeItem("expendTypeCategories");
            dispatch(success({ expendTypeCategory: expend_type_category }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "create charge type category failed";
          }
          break;
        }

        case API_ACTION.API_UPDATE_EXPEND_TYPE: {
          const { expend_type } =
            await BoxappExpendTypeBackend.updateExpendType(...payload);
          if (expend_type) {
            successMsg = "Update field Success";
            dispatch(success({ expendType: expend_type }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update charge type failed";
          }
          break;
        }
        case API_ACTION.API_GET_NOTIFICATIONS: {
          const { notifications } =
            await BoxappNotificationBackend.getNotifications(...payload);
          if (notifications) {
            dispatch(success({ notifications: notifications }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update notifications failed";
          }
          break;
        }
        case API_ACTION.API_UPDATE_NOTIFICATIONS: {
          const { notifications } =
            await BoxappNotificationBackend.updateNotifications(...payload);
          if (notifications) {
            dispatch(success({ notifications: notifications }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update notifications failed";
          }
          break;
        }
        case API_ACTION.API_GET_SETTINGS: {
          const { settings } = await BoxappAppSettingBackend.getAppSettings(
            ...payload
          );
          if (settings) {
            dispatch(success({ settings: settings }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update settings failed";
          }
          break;
        }
        case API_ACTION.API_SET_SETTINGS: {
          const { settings } = await BoxappAppSettingBackend.setAppSetting(
            ...payload
          );
          if (settings) {
            dispatch(success({ settings: settings }));
            APICallStack.pop();
            if (APICallStack.length === 0) {
              setLoading(false);
            }
          } else {
            throw "update settings failed";
          }
          break;
        }
        case API_ACTION.API_CREATE_TEMPLATE:
          {
            const { template } = await BoxappTemplateBackend.createTemplate(
              ...payload
            );
            if (template) {
              successMsg = "create template Success";
              dispatch(success({ template: template }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "create template failed";
            }
          }
          break;
        case API_ACTION.API_UPDATE_TEMPLATE:
          {
            const { template } = await BoxappTemplateBackend.updateTemplate(
              ...payload
            );
            if (template) {
              successMsg = "update template Success";
              dispatch(success({ template: template }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "update template failed";
            }
          }
          break;

        case API_ACTION.API_GET_TEMPLATES:
          {
            const { templates } = await BoxappTemplateBackend.getTemplates(
              ...payload
            );
            if (templates) {
              dispatch(success({ templates: templates }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get templates failed";
            }
          }
          break;
        case API_ACTION.API_GET_TEMPLATE:
          {
            const { template } = await BoxappTemplateBackend.getTemplate(
              ...payload
            );
            if (template) {
              dispatch(success({ template: template }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get template failed";
            }
          }
          break;
        case API_ACTION.API_GEN_PDF:
          {
            const pdf = await BoxappTemplateBackend.genPdf(...payload);
            if (pdf) {
              dispatch(success({ pdf: pdf }));
              APICallStack.pop();
              if (APICallStack.length === 0) {
                setLoading(false);
              }
            } else {
              throw "get template failed";
            }
          }
          break;
        default:
      }

      if (successMsg) {
        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: successMsg,
          messageType: "success",
          message: successMsg,
        });
      }
    } catch (e) {
      APICallStack.pop();
      if (APICallStack.length === 0) {
        setLoading(false);
      }

      if (e) {
        console.log(apiAction, e);
        if (e.status === 401) {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: "UnAuthorized",
            messageType: "failed",
            message: e.data.detail,
            onConfirm: () => {
              history.push("/error-403");
            },
          });
          dispatch(error("permission denied"));
          // history.push("/error-403")
        } else if (e.status === 403) {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: "Permission Denied",
            messageType: "failed",
            message: "you have no permission, please login again.",
            onConfirm: () => {
              history.push("/error-403");
            },
          });
          dispatch(error("permission denied"));
          // history.push("/error-403")
        } else if (e.status === 500) {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: `Error ${e?.data?.message ?? e}`,
            messageType: "failed",
            message: e?.data?.detail ?? e,
          });
        } else {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: `Error ${e?.data?.message ?? e}`,
            messageType: "failed",
            message: e?.data?.detail ?? e,
          });
        }
        // dispatch(error(e.data.message));
      } else {
        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: "Unknown Error",
          messageType: "failed",
          message: e.data.detail ?? "",
          onConfirm: () => {
            if (e.message === "Network Error") {
              history.push("/login");
            }
          },
        });
        // dispatch(error(e.message));
      }
    } finally {
      setTimeout(apiTimeOut, 10000, "");
    }
  }, []);

  return [state, makeRequest];
};
export default UseAPI;
