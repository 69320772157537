require("dotenv").config();
export class BoxappBackend {
  baseURL: string =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_SERVER ?? ""
      : "/api";

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}
let _BoxappBackend: BoxappBackend | null = null;
const getBoxappBackend = () => {
  if (!_BoxappBackend) {
    _BoxappBackend = new BoxappBackend();
  }
  return _BoxappBackend;
};

export { getBoxappBackend };
