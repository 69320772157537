import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Invoice, InvoiceType } from "../models/invoice";
import { Payment } from "../models/payment";
import { PaidItem } from "src/models/paidItem";

class BoxappInvoiceBackend extends BoxappBackend {
  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */

  createInvoice = async (
    orderId: string,
    param: {
      // order_id: string;
      discount_amount: number;
      order_type?: string;
      invoice_type: InvoiceType;
      paid_items: PaidItem[];
      remarks?: string;
      wallet_id?: string;
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/orders/${orderId}/invoices`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getInvoices = async (filter: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/invoices`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: filter,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getInvoice = async (id: string, order_type?: string): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/invoices/${id}?order_type=${order_type ?? ""}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  cancelInvoice = async (id: string): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/invoices/${id}/cancel`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateInvoice = async (
    id: string,
    invoice: {
      discount_amount: 0;
      status?: string;
      is_bad_debt?: boolean;
      wallet_id?: string;
      remarks?: string;
      paid_items?: PaidItem[];
      // paid_items: {
      //   id: string;
      //   item_name: {
      //     en: string;
      //     ch: string;
      //     zh: string;
      //   };
      //   start_date: string;
      //   end_date: string;
      //   item_type: string;
      //   original_price: number;
      //   discount_amount: number;
      //   final_price: number;
      //   item_id: string;
      //   quantity_start: number;
      //   quantity_end: number;
      //   item: any;
      // }[]
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/invoices/${id}`,
        invoice,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  voidInvoice = async (id: string): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/invoices/${id}/void`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updatePayment = async (
    paymentID: string,
    payment: {
      ref_no: string;
      remarks: string;
      payment_method: string;
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/payments/${paymentID}`,
        payment,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  voidPayment = async (paymentID: string): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/payments/${paymentID}/void`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  createPaidItem = async (
    orderId: string,
    invoiceId: string,
    param: any
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/orders/${orderId}/invoices/${invoiceId}/paid-items`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  updatePaidItem = async (
    orderId: string,
    invoiceId: string,
    paidItemId: string,
    paidItem: {
      sort?: number;
      start_date?: number;
      end_date?: number;
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/orders/${orderId}/invoices/${invoiceId}/paid-items/${paidItemId}`,
        paidItem,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  deletePaidItem = async (
    orderId: string,
    invoiceId: string,
    paidItemId: string
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.delete<ResponseResult>(
        `${this.baseURL}/orders/${orderId}/invoices/${invoiceId}/paid-items/${paidItemId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappInvoiceBackend: BoxappInvoiceBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappInvoiceBackend = () => {
  if (!_BoxappInvoiceBackend) {
    _BoxappInvoiceBackend = new BoxappInvoiceBackend();
  }
  return _BoxappInvoiceBackend;
};

export { getBoxappInvoiceBackend };
