import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Order } from "../models/order";
import { Payment } from "../models/payment";
import { DepositOrder } from "../models/depositOrder";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";
export interface CreateOrderRequest extends Order {
  invoice_remarks: string;
}
export interface CreateDepositOrderRequest extends DepositOrder {
  invoice_remarks: string;
}
class BoxappOrderBackend extends BoxappBackend {
  //deposit
  createDepositOrder = async (
    order: CreateDepositOrderRequest
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/orders?orderType=Deposit`,
        order,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );
      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  //order
  createOrder = async (order: CreateOrderRequest): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/orders?orderType=Rent`,
        order,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );
      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  getOrders = async (query: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/orders`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: query,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getOrder = async (id: string, orderType?: string): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/orders/${id}${
          orderType ? `?orderType=${orderType}` : ""
        }`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }

      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  getOrderEvents = async (id: string, query: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/orders/${id}/events`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: query,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateOrder = async (
    id: string,
    orderType: string,
    param: {
      remarks?: string;
      status?: string;
      actual_start_date?: number;
      actual_end_date?: number;
      start_date?: number;
      end_date?: number;
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/orders/${id}?orderType=${orderType}`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateOrderBranch = async (id: string, orderType: string): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/orders/${id}/branch?orderType=${orderType}`,
        {},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateOrderCustomer = async (
    id: string,
    orderType: string
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/orders/${id}/customer?orderType=${orderType}`,
        {},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  createPayment = async (
    id: string,
    payment: {
      invoice_type: InvoiceType;
      order_type: OrderType;
      ref_no?: string;
      payment_amount: number;
      remarks?: string;
      payment_method: string;
      payment_date: number;
      wallet_id?: string;
      wallet_payment_amount: number;
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/invoices/${id}/payments`,
        payment,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("orderType", payload.orderType);
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/orders/${id}/attachments`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getOrderAttachment = async (attachmentID: string): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/attachments/${attachmentID}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          responseType: "blob",
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  getOrderAttachments = async (
    id: string,
    orderType: string,
    query: any
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/orders/${id}/attachments?orderType=${orderType}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: query,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getReceipt = async (
    id: string,
    receiptType: string,
    lng: string
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/orders/${id}/receipt`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: { receipt_type: receiptType, language: lng },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  void = async (
    id: string,
    param: {
      remarks: string;
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/orders/${id}/void`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

let _BoxappOrderBackend: BoxappOrderBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappOrderBackend = () => {
  if (!_BoxappOrderBackend) {
    _BoxappOrderBackend = new BoxappOrderBackend();
  }
  return _BoxappOrderBackend;
};

export { getBoxappOrderBackend };
