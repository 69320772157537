import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Customer, CustomersMeta } from "../models/customer";
import { Data, ResponseResult } from "../api/reponseResult";
import { CustomerType } from "src/util/customerType";
import { store } from "src/util/storage";
import { MultiLangText } from "src/models/multiLangText";

class BoxappCustomerBackend extends BoxappBackend {
  /**
  /**
   * 
   * @param {*} error 
   * @returns 
   */

  getCustomers = async (filter: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/customers`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: filter,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getCustomer = async (id: string): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/customers/${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  getCustomerRentRecords = async (id: string, query: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/customers/${id}/rent-records`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: query,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  createCustomer = async (customer: {
     first_name?: string;
  last_name?: string;
  other_name?: string;
  customer_type: CustomerType;
  phone?: string;
  id_no: string;
  branches?: string[];
  status: "Active" | "Disable";
  code: string;
  address?: MultiLangText;
  title: string; 
  remarks?: string;
  email?: string;
  }): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/customers`,
        customer,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  updateCustomer = async (
    id: string,
    param: {
      first_name?: string;
      last_name?: string;
      other_name?: string;
      customer_type?: CustomerType;
      phone?: string;
      id_no?: string;
      remarks?: string;
      code?: string;
      address?: any;
      branches?: string[];
      title?: string;
      status?: string;
      meta?: any;
      email?: string;
    }
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/customers/${id}`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  createWallet = async (customerId: string, param: any): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/customers/${customerId}/wallets`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  withdraw = async (
    customerId: string,
    walletId: string,
    param: any
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/customers/${customerId}/wallet/${walletId}/withdraw`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  topup = async (
    customerId: string,
    walletId: string,
    param: any
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/customers/${customerId}/wallet/${walletId}/topup`,
        param,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  getWallets = async (customerId: string, param: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/customers/${customerId}/wallets`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: param,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  getTransactions = async (
    customerId: string,
    walletId: string,
    param: any
  ): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/customers/${customerId}/wallet/${walletId}/transactions`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: param,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  uploadAttachment = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);

      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/customers/${id}/attachments`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getAttachment = async (attachmentID: string): Promise<any> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/attachments/${attachmentID}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          responseType: "blob",
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as any;
    } catch (err) {
      throw err;
    }
  };

  getAttachments = async (id: string, query: any): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/customers/${id}/attachments`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: query,
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  sendNotice = async (payload: {
    customer_id?: string;
    notice_type?: string;
    template_type?: string;
    order_type?: string;
    order_id?: string;
    branch_id?: string;
    invoice_id?: string;
    storage_id?: string;
    payment_id?: string;
    contract_id?: string;
    file_name?: string;
  }): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/customers/${payload.customer_id}/send-notice`,
        payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappCustomerBackend: BoxappCustomerBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappCustomerBackend = () => {
  if (!_BoxappCustomerBackend) {
    _BoxappCustomerBackend = new BoxappCustomerBackend();
  }
  return _BoxappCustomerBackend;
};

export { getBoxappCustomerBackend };
