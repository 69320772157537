import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
import { Branch } from "../models/branch";

class BoxappBranchBackend extends BoxappBackend {
  createBranch = async (branch: Branch): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/branches`,
        branch,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  disableBranch = async (id: string, disable: any): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/branches/${id}/disable`,
        { disable: disable },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  sortBranch = async (id: string, sort: any): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/branches/${id}/sort`,
        { sort: sort },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateBranch = async (id: string, branch: Branch): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/branches/${id}`,
        branch,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateBranchTandC = async (id: string, payload: any): Promise<Data> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/branches/${id}/tandc`,
        payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getBranches = async (): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/branches`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getBranch = async (id: string): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/branches/${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }

      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  uploadBranchLogo = async (id: string, payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/branches/${id}/logo`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappBranchBackend: BoxappBranchBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappBranchBackend = () => {
  if (!_BoxappBranchBackend) {
    _BoxappBranchBackend = new BoxappBranchBackend();
  }
  return _BoxappBranchBackend;
};

export { getBoxappBranchBackend };
