import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";

class BoxappCompanyBackend extends BoxappBackend {
  updateCompany = async (company: any): Promise<Data> => {
    const req = company;
    delete req.logo;
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/company/info`,
        req,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  getCompany = async (): Promise<Data> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/company/info`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {},
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateCompanyLogo = async (payload: any): Promise<Data> => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);

      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/company/logo`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  updateCompanyTandC = async (payload: any): Promise<Data> => {
    try {
      const { data, status } = await axios.put<ResponseResult>(
        `${this.baseURL}/company/tandc`,
        payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappCompanyBackend: BoxappCompanyBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappCompanyBackend = () => {
  if (!_BoxappCompanyBackend) {
    _BoxappCompanyBackend = new BoxappCompanyBackend();
  }
  return _BoxappCompanyBackend;
};

export { getBoxappCompanyBackend };
