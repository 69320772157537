import axios from "axios";
import { BoxappBackend } from "./boxapp_helper";
import { Data, ResponseResult } from "../api/reponseResult";
class BoxappReportBackend extends BoxappBackend {
  monthlyReceiptReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/monthly/receipt`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  monthlyReceiptTotalReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/monthly/receipt-total`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  monthlyDepositSummaryReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/monthly/deposit-summary`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  monthlyPropertyReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/monthly/property`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  monthlyPropertyTotalReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/monthly/property-total`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  orderDetailReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/orders/detail`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  orderDetailTotalReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/orders/detail-total`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  depositDetailReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/deposit-orders/detail`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  depositDetailTotalReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.post<ResponseResult>(
        `${this.baseURL}/reports/deposit-orders/detail-total`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status: any) => {
            return status ? true : false;
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  storagesReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/reports/storages`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {
            ...params,
            branches: undefined,
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };

  ordersReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/reports/orders`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {
            ...params,
            branches: undefined,
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  contractsReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/reports/contracts`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {
            ...params,
            branches: undefined,
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  monthlyRentalReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/reports/monthly/rental`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {
            ...params,
            branches: undefined,
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  monthlyDepositReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/reports/monthly/deposit`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {
            ...params,
            branches: undefined,
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
  monthlyDepositSettledReport = async (params: any): Promise<any> => {
    try {
      const { data, status } = await axios.get<ResponseResult>(
        `${this.baseURL}/reports/monthly/deposit/settled`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          // "Content-Type": "application/json",
          validateStatus: (status) => {
            return status ? true : false;
          },
          params: {
            ...params,
            branches: undefined,
          },
        }
      );

      if (status !== 200) {
        throw { data: data, status: status };
      }
      return data?.data as Data;
    } catch (err) {
      throw err;
    }
  };
}

let _BoxappReportBackend: BoxappReportBackend | null = null;

/**
 * Returns the firebase backend
 */
const getBoxappReportBackend = () => {
  if (!_BoxappReportBackend) {
    _BoxappReportBackend = new BoxappReportBackend();
  }
  return _BoxappReportBackend;
};

export { getBoxappReportBackend };
